import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'appendPx'
})
export class AppendPxPipe implements PipeTransform {
  transform(value?: number) {
    if (!value) {
      return;
    }
    return `${value}px`;
  }
}