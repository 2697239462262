import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CdkDrag, CdkDragDrop, CdkDragMove, CdkDropList, moveItemInArray} from "@angular/cdk/drag-drop";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'lib-tree-node-reorder-dialog',
  standalone: true,
  imports: [
    CdkDropList, CdkDrag, MatButton,
  ],
  templateUrl: './tree-node-reorder-dialog.component.html',
  styleUrl: './tree-node-reorder-dialog.component.scss'
})
export class TreeNodeReorderDialogComponent {
  nodes: any[];

  constructor(
    public dialogRef: MatDialogRef<TreeNodeReorderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { nodes: any[] }
  ) {
    this.nodes = [...data.nodes];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.nodes, event.previousIndex, event.currentIndex);
  }

  onSave(): void {
    const newOrder: number[] = []
    this.nodes.forEach((node) => {
      newOrder.push(node.id);
    })
    this.dialogRef.close(newOrder);
  }
}
