<div class="component-wrapper">
    <div class="middle-title">
        <div>{{translatedEntityName}}</div>
        @if ([entityName + '.' + BasePermissions.create] | hasPermission | async) {
            <button mat-raised-button color="primary" (click)="goToView()">Aggiungi {{translatedEntityName}}</button>
        }
    </div>

    <mat-progress-spinner
        [diameter]="52"
        color="primary"
        mode="indeterminate"
        *ngIf="!entityListEnabled"
    ></mat-progress-spinner>

    <lib-frame-entity-table
        [entityName]="entityName"
        [entityConfig]="entityConfig"
        (goToView)="goToView($event)"
        (openDeleteConfirmationDialog)="openDeleteConfirmationDialog($event)"
        [actions]="actions"
        (actionEmitter)="emitAction($event)"
        *ngIf="entityListEnabled"
    >
        <ng-content></ng-content>
    </lib-frame-entity-table>
</div>
