import {ExportOptionsModel} from '../../model/export-options.model';
import {castTableCell} from './utils';
import {SpecialColumnsEnum} from '../../enums/special-columns.enum';
import {ColumnModel} from '../../model/column.model';
import * as FileSaver from 'file-saver';

export function createExcelFile(elements: any, columnList: ColumnModel[], options?: ExportOptionsModel) {
  let maxColumnHeadersWidth = 10;
  const rows: any[] = [];

  for(const element of elements) {
    const row: any = {}
    const columnListToUse = options?.alternativeColumns ?? columnList;

    for(const column of columnListToUse) {
      if(column.def != SpecialColumnsEnum.actions && column.def != SpecialColumnsEnum.selection) {
        let value;
        if (column.type === 'date') {
          value = element[column.def];
        } else {
          value = column?.value(element);
        }
        row[column.name] = castTableCell(value, column.exportedType || column.type);
        maxColumnHeadersWidth = column.name.length > maxColumnHeadersWidth ? column.name.length : maxColumnHeadersWidth;
      }
    }
    rows.push(row);
  }

  saveAsExcelFile(rows, columnList, maxColumnHeadersWidth, options);
}

function saveAsExcelFile(rows: any[],columnList: ColumnModel[], maxColumnHeadersWidth: number, options?: ExportOptionsModel ) {
  import('xlsx').then(xlsx => {
    const worksheet = xlsx.utils.json_to_sheet(rows);
    const columnListToUse = options?.alternativeColumns ?? columnList;
    worksheet['!cols'] = columnListToUse.map(column => {
      return {wch: maxColumnHeadersWidth};
    }); // set columns header width

    const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
    const defaultFileName: string = options?.fileName ?? 'export_' + new Date().getTime();
    saveAsExcelFileBlob(excelBuffer, defaultFileName);
  });
}


function saveAsExcelFileBlob(buffer: any, fileName: string): void {
  let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let EXCEL_EXTENSION = ".xlsx";
  const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
  FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
}
