import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {RoleModel} from "../../model/role.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RoleService} from "../../service/role.service";
import {RoleFunctionService} from "../../service/role-function.service";
import {RoleFunctionModel} from "../../model/role-function.model";
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import { BaseForm } from '@overflo-srl/dynamic-form';
import { DynamicFormComponent } from '@overflo-srl/dynamic-form';
import { RoleFormResultModel } from '../../model/role-form-result.model';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { MatButtonModule } from '@angular/material/button';

export class SelectableFunctionRole {
  selected: boolean;
  roleFunction: RoleFunctionModel;
  constructor(roleFunction: RoleFunctionModel, selected = false) {
    this.selected = selected;
    this.roleFunction = roleFunction;
  }
}

export class SelectableFunctionGroup {
  group: string;
  functions: SelectableFunctionRole[];
  constructor(group: string) {
    this.group = group;
    this.functions = [];
  }
}

@Component({
  standalone: true,
  imports: [
    DynamicFormComponent,
    MatButtonModule,
  ],
  selector: 'app-role-edit-dialog',
  templateUrl: './role-edit-dialog.component.html',
  styleUrls: ['./role-edit-dialog.component.scss']
})
export class RoleEditDialogComponent implements OnInit {

  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  role?: RoleModel;
  forms: BaseForm[] = [];
  submitting: boolean = false;
  selectableFunctionGroups: SelectableFunctionGroup[] = [];
  roleFunctions: RoleFunctionModel[] = [];
  selectedPermissions?: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {role: RoleModel},
    private dialogRef: MatDialogRef<RoleEditDialogComponent>,
    private roleService: RoleService,
    private roleFunctionService: RoleFunctionService,
    private snackBarService: SnackBarService,
  ) {
    this.role = data.role;
  }

  async ngOnInit(): Promise<void> {
    await this.loadFunctions();

    const selectableRoleFunctions: RoleFunctionModel[] = [];
    const selectedRoleFunctions: RoleFunctionModel[] = [];

    this.selectableFunctionGroups.forEach((selectableFunctionGroup) => {
      selectableFunctionGroup.functions.forEach(selectableFunction => {
        if (selectableFunction.selected) {
          selectedRoleFunctions.push(selectableFunction.roleFunction);
        }
        selectableRoleFunctions.push(selectableFunction.roleFunction);
      });
    });

    const forms = [
      new BaseForm({
        key: 'name',
        value: this.role?.name,
        label: 'Nome',
        controlType: 'text',
        required: true,
        readonly: false,
      }),
      new BaseForm({
        key: 'description',
        value: this.role?.description,
        label: 'Descrizione',
        controlType: 'text',
        required: false,
        readonly: false,
      }),
      new BaseForm({
        key: 'functions',
        value: selectedRoleFunctions,
        options: selectableRoleFunctions.map((selectableRoleFunction) => {
          return {
            key: `${selectableRoleFunction.name} - ${selectableRoleFunction.description}`,
            mnemonicId: selectableRoleFunction.name,
            description: '',
            value: selectableRoleFunction
          };
        }),
        label: 'Funzioni',
        controlType: 'multipleselect',
        required: true,
        readonly: false,
        fullWidth: true
      }),
    ];

    this.forms = this.forms.concat(forms);
  }

  async loadFunctions() {
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: "permissions"}]);
    const allFunctions : RoleFunctionModel[] = await this.roleFunctionService.getMany(qb.query());
    if(allFunctions) {
      if(this.role?.id) {
        this.roleFunctions = await this.getRoleFunctions();
      }
      const groups = Array.from(new Set(allFunctions.map(el => el.group)));

      for(const group of groups) {
        const groupFunctions = allFunctions.filter(el => el.group == group) || []; // merge functions by same group
        const selectableFunctionGroup = new SelectableFunctionGroup(group as string);

        selectableFunctionGroup.functions = groupFunctions.map(el => new SelectableFunctionRole(el, this.isRoleFunction(this.roleFunctions, el)));
        this.selectableFunctionGroups.push(selectableFunctionGroup);
      }
    }
  }

  isRoleFunction (roleFunctions: RoleFunctionModel[], roleFunction: RoleFunctionModel) { // checks if roleFunction is a function that is related to this role
    return !!roleFunctions.find(el => el.id == roleFunction.id);
  }

  async getRoleFunctions() : Promise<RoleFunctionModel[]> {
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: "functions"}, {field: "functions.permissions"}]);
    const role = await this.roleService.getOne(this.role?.id as number , qb.query());
    return role?.functions || [];
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async save(formResult: RoleFormResultModel) {
    const selectedPermissions: string[] = [];

    formResult.functions.forEach((_function) => {
      _function.permissions.forEach((permission) => {
        if (!selectedPermissions.includes(permission.name)) {
          selectedPermissions.push(permission.name);
        }
      });
    });
    this.selectedPermissions = selectedPermissions;

    if (!this.submitting) {
      return;
    }

    let result;
    if(this.role?.id) {
      result = await this.roleService.updateOne({
        id: this.role.id,
        version: this.role.version,
        ...formResult
      });
      this.snackBarService.openInfoSnackBar(`Ruolo aggiornato con successo`);
    } else {
      result = await this.roleService.createOne(formResult as RoleModel);
      this.snackBarService.openInfoSnackBar(`Ruolo creato con successo`);
    }
    this.dialogRef.close(result === null ? true : result);
  }
}
