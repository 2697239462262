<div class="background-overlay"></div>

<div class="header">
    <img src="/img/logo.png" />
</div>

<div class="component-wrapper">

    @if (isTokenValid) {
        <div class="middle-title">Ripristino password</div>
        <lib-dynamic-form #dynamicForm [forms]="forms" (send)="save($event)" [showButtonAndEmit]="false" [showConfirmationDialog]="false"></lib-dynamic-form>
        <div>
            <button mat-raised-button color="primary" (click)="confirm()">Aggiorna password</button>
        </div>
    } @else {
        <div class="middle-title">Ripristino password</div>
        <div class="paragraph">Il link utilizzato non è più valido, è possibile richiederlo nuovamente dalla pagina di accesso.</div>
        <div>
            <button mat-raised-button color="accent" (click)="navigateToLoginPage()">Accedi</button>
        </div>
    }
</div>

