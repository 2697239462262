import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormComponent, BaseForm } from '@overflo-srl/dynamic-form';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { PasswordResetFormResultModel } from '../../models/password-reset-form-result.model';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  standalone: true,
  imports: [
    DynamicFormComponent,
    MatButtonModule,
  ],
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  forms: BaseForm[] = [];
  submitting: boolean = false;

  token?: string;
  isTokenValid: boolean = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async params => {
      const token = params['token'];
      this.token = token;

      if (!this.token) {
        return;
      }
      this.isTokenValid = await this.authService.isPasswordResetTokenValid(this.token);

      const forms = [
        new BaseForm({
          key: 'password',
          value: null,
          label: 'Nuova password',
          controlType: 'text',
          type: 'password',
          required: true,
          readonly: false,
        }),
        new BaseForm({
          key: 'repeatPassword',
          value: null,
          label: 'Conferma nuova password',
          controlType: 'text',
          type: 'password',
          required: true,
          readonly: false,
        }),
      ];

      this.forms = this.forms.concat(forms);
    });
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async save(formResult: PasswordResetFormResultModel) {
    if (!this.submitting) {
      return;
    }
    const {password, repeatPassword} = formResult;

    if (password !== repeatPassword) {
      this.snackBarService.openErrorSnackBar(`Le password inserite non corrispondono`);
      return;
    }

    if (!this.token) {
      this.snackBarService.openErrorSnackBar(`Token non valido`);
      return;
    }

    // token serve per validare che sia effettivamente lo stesso utente a richiedere
    await this.authService.resetPassword(this.token, password, repeatPassword);
    
    this.snackBarService.openInfoSnackBar(`Password modificata con successo, stai per essere reinderizzato...`);

    setTimeout(() => {
      this.navigateToLoginPage();
    }, 3000);
  }

  navigateToLoginPage() {
    this.router.navigate(['login']);
  }

}
