<form
  (click)="$event.stopPropagation()"
  (keydown.enter)="applyFilter(true)"
  (keydown.tab)="$event.stopPropagation()"
  [formGroup]="filterForm"
  class="form-menu"
>

  <div class="form-menu-head">
    <ng-container *ngIf="!filterForm.controls['column'].value">Seleziona proprietà</ng-container>
    <ng-container *ngIf="filterForm.controls['column'].value">
      <div>Inserisci valore</div>
      <div class="form-menu-head-selected-property">
        {{ filterForm.controls['column'].value }}
      </div>
    </ng-container>
  </div>

  <div class="fields-select" *ngIf="!getFilterColumnType()">
    <button mat-button *ngFor="let column of filteredColumnsForm$ | async" class="fields-select-field"
            (click)="selectFilter(column)">
      {{ column.name }}
    </button>
  </div>

  <ng-container
    *ngIf="filterForm.controls['column'].value && !(hasBoolishColumnType() && !(columnList | getColumnFilterOptions: filterForm))">

    <mat-select class="filter-select" [formControl]="filterOptionsRadioGroup">
      <mat-option *ngFor="let option of filterOptions" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </ng-container>

  <div class="form-menu-content" *ngIf="filterForm.controls['column'].value &&
       ((hasBoolishColumnType() && !(columnList | getColumnFilterOptions: filterForm)) ||
        filterOptionsRadioGroup.value | mustSpecifyValue)">
    <!-- date -->
    <ng-container *ngIf="getFilterColumnType() == TypesEnum.DATE">
      <!-- date start -->
      <mat-form-field appearance="outline">
        <mat-label>Data inizio</mat-label>
        <input [matDatepicker]="dateStartPicker" formControlName="start" matInput>
        <mat-datepicker-toggle [for]="dateStartPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dateStartPicker></mat-datepicker>
      </mat-form-field>

      <!-- date end -->
      <mat-form-field appearance="outline">
        <mat-label>Data fine</mat-label>
        <input [matDatepicker]="dateEndPicker" formControlName="end" matInput>
        <mat-datepicker-toggle [for]="dateEndPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dateEndPicker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <!-- number -->
    <ng-container *ngIf="getFilterColumnType() == TypesEnum.NUMBER">
      <ng-container *ngIf="!(columnList | getColumnFilterOptions: filterForm); else filterOptionsSelect">
        <div>
          <mat-slide-toggle formControlName="isExactValue" color="primary"></mat-slide-toggle>
          <mat-label>Valore esatto</mat-label>
        </div>

        <ng-container *ngIf="filterForm.controls['isExactValue'].value">
          <!-- number exact value -->
          <mat-form-field appearance="outline">
            <mat-label>Valore</mat-label>
            <input type="number" formControlName="value" matInput>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!filterForm.controls['isExactValue'].value">
          <!-- number start -->
          <mat-form-field appearance="outline">
            <mat-label>Da</mat-label>
            <input type="number" formControlName="start" matInput>
          </mat-form-field>

          <!-- number end -->
          <mat-form-field appearance="outline">
            <mat-label>a</mat-label>
            <input type="number" formControlName="end" matInput>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>

    <!--string-->
    <ng-container *ngIf="getFilterColumnType() == TypesEnum.STRING">
      <ng-container *ngIf="!(columnList | getColumnFilterOptions: filterForm); else filterOptionsSelect">
        <mat-form-field appearance="outline">
          <mat-label>Inserisci valore</mat-label>
          <input type="text" formControlName="value" matInput [matAutocomplete]="filterDefault" autocomplete="off">

          <!--filter default-->
          <mat-autocomplete #filterDefault="matAutocomplete" autoActiveFirstOption>
            <mat-option *ngFor="let option of getFilterDefault()" [value]="option.value">
              {{ option.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </ng-container>

    <!--boolean-->
    <ng-container *ngIf="hasBoolishColumnType()">
      <ng-container *ngIf="!(columnList | getColumnFilterOptions: filterForm); else filterOptionsSelect">
        <mat-slide-toggle formControlName="value" color="primary"></mat-slide-toggle>
      </ng-container>
    </ng-container>

    <ng-template #filterOptionsSelect>
      <mat-form-field appearance="outline">
        <mat-label>Seleziona valore</mat-label>
        <mat-select formControlName="value">
          <mat-option *ngFor="let option of (columnList | getColumnFilterOptions: filterForm)"
                      [value]="option.realValue">
            {{ option.displayedValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </div>

  <div class="form-menu-actions" *ngIf="filterForm.controls['column'].value">
    <button mat-stroked-button (click)="closeFilterMenu()">Annulla</button>
    <button mat-raised-button color="primary" (click)="applyFilter(true)">Conferma</button>
  </div>
</form>
