import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url?: string) {
    if (!url) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
