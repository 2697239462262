import { registerLocaleData } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AuthModule } from "./auth/auth.module";
import { FrameEntityModule } from "./frame-entity/frame-entity.module";
import { DynamicFormConfig } from "./frame-entity/models/dynamic-form-config.model";
import { FullTableConfig } from "./frame-entity/models/full-table-config.model";
import { HttpConfig } from "./frame-entity/models/http-config.model";
import { TranslationConfig } from "./frame-entity/models/translation-config.model";
import { FrameEntityHttpService } from "./frame-entity/services/frame-entity-http/frame-entity-http.service";
import { metaReducers, reducers } from "./shared/reducers";
import { UserModule } from "./user/user.module";
import localeIt from '@angular/common/locales/it';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { EnvironmentEnum } from "./shared/enums/environment.enum";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  imports: [
    AuthModule,
    UserModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    FrameEntityModule,
  ],
  providers: [
    provideAnimationsAsync(),
    {provide: DateAdapter, useClass: NativeDateAdapter},
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ],
  declarations: [],
  bootstrap: [],
  exports: [],
})
export class FrameModule {
    static forRoot(
      config: {
        environment?: EnvironmentEnum,
        dateFormat?: string,
        dateTimeFormat?: string,
        locale?: string,
        httpConfig: HttpConfig, 
        fullTableConfig?: FullTableConfig,
        dynamicFormConfig?: DynamicFormConfig,
        translationConfig?: TranslationConfig,
      }
    ): ModuleWithProviders<FrameModule> {
      return {
        ngModule: FrameModule,
        providers: [
          FrameEntityHttpService, 
          {provide: 'environment', useValue: config.environment},
          {provide: 'dateFormat', useValue: config.dateFormat},
          {provide: 'dateTimeFormat', useValue: config.dateTimeFormat},
          {provide: 'locale', useValue: config.locale},
          {provide: 'BASE_PATH', useValue: config.httpConfig.basePath},
          {provide: 'fullTableConfig', useValue: config.fullTableConfig},
          {provide: 'dynamicFormConfig', useValue: config.dynamicFormConfig},
          {provide: 'translationConfig', useValue: config.translationConfig},
        ]
      };
    }
  }

