<div class="component-wrapper">
  <div class="middle-title">
    {{ create ? "Aggiungi Nodo" : 'Modifica Nodo' }}
  </div>

        @if (portal) {
            <ng-template [cdkPortalOutlet]="portal"></ng-template>
        }
        @else if (loading) {
            <div class="loader">
                <mat-spinner diameter="28" mode="indeterminate"></mat-spinner>
            </div>
        }
        @else if (forms && forms.length) {
            <lib-dynamic-form
                #dynamicForm
                [forms]="forms"
                (send)="save($event)"
                [showButtonAndEmit]="true"
                [showConfirmationDialog]="false"
                class="form-custom-padding"
            ></lib-dynamic-form>
        }
</div>
