import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, first, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as fromAuth from '../../../auth/reducers/auth.reducers';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { getToken } from '../../../auth/selectors/auth.selector';
import { LoginResponseDto } from '../../models/login.model';
import { logout } from '../../../auth/actions/auth.actions';
import { SnackBarService } from '../snackbar/snackbar.service';
import { clearAuthStorageData } from '../../reducers';

@Injectable()
export class ApplicationInterceptor implements HttpInterceptor {
  private token$: Observable<string>;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private router: Router, 
    private store: Store<fromAuth.State>, 
    private authService: AuthService,
    private snackBarService: SnackBarService,
  ) {
    this.token$ = this.store.select(getToken);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.token$.pipe(
      first(), // Non voglio che al cambiare del token partano altre richieste uguali a quella ricevuta
      switchMap(token => {
        const authReq = !!token
          ? request.clone({
              setHeaders: { Authorization: 'Bearer ' + token },
            })
          : request;
        return next.handle(authReq);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error.status === 498) {
          return this.handle498Error(request, next);
        } else {
          let errorMessage = '';
          switch (error.status) {
            case 0:
              if (navigator.onLine) {
                errorMessage = 'server non disponibile';
              } else {
                errorMessage = 'internet non disponibile';
              }
              break;
            case 403:
              if (request.url.includes('refresh')) {
                this.store.dispatch(logout());
              } else if (!request.url.includes('login')) {
                errorMessage = 'Non hai i permessi per eseguire questa operazione';
              } else {
                return throwError(error);
              }
              break;
            default:
              errorMessage = `${error.error.message ? error.error.message : error.message}`;
              break;
          }
          if (errorMessage) {
            this.snackBarService.openErrorSnackBar(errorMessage);
          }
          return throwError(errorMessage);
        }
      })
    );
  }

  private static addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (localStorage.getItem('isRefreshing') != 'true') {
      this.refreshTokenSubject.next(null);

      return this.authService.refreshTokens().pipe(
        switchMap((loginResponseDto: LoginResponseDto) => {
          const jwt = loginResponseDto?.token?.jwt;
          if (!jwt) {
            this.router.navigate(['login']);
            throw new Error('Invalid token');
          }
          this.refreshTokenSubject.next(jwt);
          return next.handle(ApplicationInterceptor.addToken(request, jwt));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        switchMap(jwt => {
          if (!jwt) {
            clearAuthStorageData();
            this.router.navigate(['login']);
          }
          return next.handle(ApplicationInterceptor.addToken(request, jwt));
        })
      );
    }
  }

  private handle498Error(request: HttpRequest<any>, next: HttpHandler) {
    clearAuthStorageData();
    this.router.navigate(['login']);
    return next.handle(request);
  }
}
