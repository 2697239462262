@if (areRootsLoading | async) {
    <div class="loader">
        <mat-spinner diameter="28" mode="indeterminate"></mat-spinner>
    </div>
} @else {
    @if (!disableAddRootBtn && ([entityName + '.' + BasePermissions.create] | hasPermission | async)) {
        <div class="add-root">
            <button mat-raised-button color="primary" (click)="openNodeCreateOrUpdateDialog(undefined, true)">Aggiungi radice</button>
        </div>
    }
    <div class="tree-wrapper">
        <cdk-tree
                #tree
                [dataSource]="roots"
                [childrenAccessor]="getChildren"
                [trackBy]="trackBy"
                [expansionKey]="expansionKey"
            >
            <cdk-tree-node
                *cdkTreeNodeDef="let node"
                cdkTreeNodePadding
                [cdkTreeNodeTypeaheadLabel]="node.raw.name"
                [isExpandable]="node.leaf"
                (expandedChange)="onExpand(node, $event)"
                [ngClass]="{'leaf': node.raw.leaf}"
            >
                <!-- Spinner when node is loading children; this replaces the expand button. -->
                @if (node.raw | areTreeChildrenLoading) {
                    <div class="loader">
                        <mat-spinner diameter="28" mode="indeterminate"></mat-spinner>
                    </div>
                }

                @if (!node.raw.leaf && !(node.raw | areTreeChildrenLoading)) {
                    <button
                        mat-icon-button
                        class="icon-button expand-button"
                        cdkTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.raw.name"
                    >
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{tree.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                }

                <!-- Spacer for leaf nodes -->
                @if (node.raw.leaf) {
                    <div class="toggle-spacer"></div>
                }

                <div class="template">
                    <ng-template
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ $implicit: node.raw }">
                    </ng-template>
                </div>

                <!-- Spacer for leaf nodes -->
                <div class="actions">
                    @if ((actions | actionsMerge: defaultActions: entityName | async)?.length) {
                        <button
                            mat-icon-button
                            class="icon-button actions-button"
                            [matMenuTriggerFor]="nodeMenu"
                            [ngClass]="{'visible': nodeMenuTrigger.menuOpen}"
                            matTooltip="Visualizza azioni"
                            #nodeMenuTrigger="matMenuTrigger"
                        >
                           <mat-icon>more_vert</mat-icon>
                         </button>
    
                         <mat-menu #nodeMenu="matMenu">
                            <div fxLayout="column">
                                <ng-container *ngFor="let action of (actions | actionsMerge: defaultActions: entityName | async)">
                                      <button mat-menu-item (click)="emitAction(action, node)">
                                            <mat-icon>{{action.icon}}</mat-icon>
                                            {{action.type}}
                                        </button>
                                </ng-container>
                            </div>
                        </mat-menu>
                    }
                </div>
            </cdk-tree-node>
        </cdk-tree>
    </div>
}
