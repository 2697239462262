<div class="component-content">
  <div class="filters">
    <mat-form-field appearance="outline" class="search">
      <mat-label class="search-label">Ricerca veloce<mat-icon>bolt</mat-icon></mat-label>
      <input matInput placeholder="Ricerca veloce"  [ngModel]="quickSearchText" (ngModelChange)="updateQuickSearchWithDebounce($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <button
      class="filters-button"
      mat-stroked-button
      (click)="export()"
      [matTooltip]="'Esporta in excel'"
      *ngIf="enableExport"
    >
      <mat-icon>download</mat-icon>
    </button>
  </div>
</div>

<mat-chip-listbox aria-label="Table filters" *ngIf="elementList?.data?.length || chipList?.length">
  <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger" [mat-menu-trigger-for]="filterMenu" class="add-filter-chip">
    <div class="add-filter-chip-content">
      <mat-icon>filter_list</mat-icon>
      <div>Filtra</div>
    </div>
  </button>

  <div class="filter-option-container">
    <ng-container *ngFor="let chip of chipList">
      <mat-chip-option (removed)="removeChip(chip, 'value')" *ngIf="chip.value || chip.value === false">
        {{chip.column}} {{ chip.operation ? chip.operation : '='}} {{chip.value === null || chip.value === '' ? 'NULL' : (chip.operation | isChipOperationIsNullOrNotNull) ? '' : ( chip.column | getFilterOptionDisplayedValue: chip.value : columnList : filterComponent.getFormColumn() ) ?? chip.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      <mat-chip-option (removed)="removeChip(chip, 'start')" *ngIf="chip.start">
        {{chip.column}} > {{chip.start === null || chip.start === '' ? 'NULL' : chip.start}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      <mat-chip-option (removed)="removeChip(chip, 'end')" *ngIf="chip.end">
        {{chip.column}} < {{chip.end === null || chip.end === '' ? 'NULL' : chip.end}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </ng-container>
  </div>

  <mat-menu #filterMenu="matMenu" [overlapTrigger]="false">
    <lib-full-table-filter
      #filterComponent
      [chipList]="chipList"
      [actions]="actions"
      [columnList]="columnList"
      [defaultFilter]="defaultFilter"></lib-full-table-filter>
  </mat-menu>

  <ng-container *ngIf="enableSettingsMenu">
    <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger" [mat-menu-trigger-for]="settingsMenu" class="add-filter-chip" (menuClosed)="fullTableSettingsComponent.reset()">
      <div class="add-filter-chip-content">
        <mat-icon>settings</mat-icon>
        <div>Impostazioni</div>
      </div>
    </button>
  
    <mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">
      <lib-full-table-settings
        #fullTableSettingsComponent
        [columnList]="columnList"
        (widthChange)="onWidthChange($event)"
        (orderChange)="onOrderChange($event)"
        (click)="$event.stopPropagation()"
      ></lib-full-table-settings>
    </mat-menu>
  </ng-container>

</mat-chip-listbox>

<mat-progress-bar *ngIf="loading" color="accent" mode="query"></mat-progress-bar>

<table mat-table [dataSource]="elementList" matSort class="hide-on-mobile">
  <ng-container
    *ngFor="let column of columnList | viewableColumnList"
    [matColumnDef]="column.def"
  >
    <th mat-header-cell *matHeaderCellDef [disabled]="!column.sort" mat-sort-header style="flex: 1; border-width: 0"
    [ngStyle]="{
      'max-width': ((column.width | appendPx) || column.maxWidth) || undefined,
      'min-width': ((column.width | appendPx) || column.minWidth) || undefined
    }"
    >
      <ng-container *ngIf="column.def != SpecialColumnsEnum.selection || (isOnlyOneSelectable && column.def === SpecialColumnsEnum.selection)">
        <span [ngClass]="{'only-one-selectable-title': isOnlyOneSelectable && column.def === SpecialColumnsEnum.selection}">{{ column.name }}</span>
      </ng-container>

      <ng-container *ngIf="isSelectable && column.def === SpecialColumnsEnum.selection && !isOnlyOneSelectable">
        <mat-checkbox
          (change)="onSelectAllClick($event)"
          color="primary"
          [formControl]="selectControl"
          class="selection-checkbox"
        >
          Selezione ({{selectedElements.length}})
        </mat-checkbox>

        <button mat-icon-button
                matTooltip="Per poter selezionare contemporaneamente tutti gli elementi nella tabella non devono esserne presenti più di {{selectAllLimit}}. Riduci il numero di elementi in tabella eseguendo una ricerca più specifica.">
          <mat-icon>help</mat-icon>
        </button>
      </ng-container>

      <mat-icon *ngIf="
          (quickSearchColumns?.includes(column.def) ||!quickSearchColumns?.length) &&
          (column.type === TypesEnum.STRING || column.type === TypesEnum.NUMBER)">
        bolt
      </mat-icon>
    </th>

    <span *ngIf="column.def !== SpecialColumnsEnum.actions && column.def !== SpecialColumnsEnum.selection">
      <mat-cell
        class="hide-if-too-long"
        (click)="clickedAction(element)"
        *matCellDef="let element"
        [ngStyle]="{
          'max-width': ((column.width | appendPx) || column.maxWidth) || undefined,
          'min-width': ((column.width | appendPx) || column.minWidth) || undefined
        }"
        >
        <mat-checkbox *ngIf="((element | applyColumnValueFunction: column) | isBooleanType) else renderstring"
                      [checked]="element | applyColumnValueFunction: column" disabled="true"></mat-checkbox>
        <ng-template #renderstring>
          <span
            class="link hide-if-too-long"
            *ngIf="column.click"
            (click)="column.click(element)"
            [matTooltip]="column.tooltip ?? element | applyColumnValueFunction: column"
          >{{element | applyColumnValueFunction: column}}</span>
          <span class="hide-if-too-long" [matTooltip]="element | applyColumnValueFunction: column" showIfTruncated *ngIf="!column.click">{{element | applyColumnValueFunction: column}}</span>
        </ng-template>
      </mat-cell>
    </span>
    <span *ngIf="column.def === SpecialColumnsEnum.selection && selectedElementsIds">
      <mat-cell (click)="clickedAction(element)" *matCellDef="let element">
        <div  class="selection-checkbox">
          <mat-checkbox [checked]="selectedElementsIds.includes(element.id)" (change)="selectSingleElement(element, $event.checked)"  color="primary">
            {{selectedElementsIds.includes(element.id) ? 'Selezionato' : 'Non selezionato'}}
          </mat-checkbox>
        </div>
      </mat-cell>
    </span>
    <span *ngIf="column.def === SpecialColumnsEnum.actions">
      <mat-cell *matCellDef="let element">
        <span *ngIf="column.value.length == 1 else moreMenu" fxLayout="column">
          <span *ngFor="let action of column.value">
           <button (click)="actions.emit({type: action.type, element: element })"
                   *ngIf="action.filter ? action.filter(element) : true"
                   [matTooltip]="action.type" mat-icon-button>
             <mat-icon>{{action.icon ? action.icon : action.type}}</mat-icon>
           </button>
          </span>
        </span>
        <ng-template #moreMenu>
          <button [matMenuTriggerFor]="entityMenu" mat-icon-button><mat-icon>more_vert</mat-icon></button>
          <mat-menu #entityMenu="matMenu">
              <div fxLayout="column">
                <div *ngFor="let action of column.value">
                 <button mat-menu-item (click)="actions.emit({type: action.type, element: element })"
                         *ngIf="action.filter ? action.filter(element) : true">
                   <mat-icon>{{action.icon ? action.icon : action.type}}</mat-icon>
                   {{action.type}}
                 </button>
                </div>
                <div>

                </div>
              </div>
          </mat-menu>
        </ng-template>
      </mat-cell>
    </span>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index; let odd = odd; " [class.odd]="odd"></mat-row>
</table>


<mat-table [dataSource]="elementList" class="hide-on-desktop">
  <ng-container matColumnDef="mobile">
    <mat-cell (click)="openMobileDialog(element)" *matCellDef="let element">{{element | applyColumnValueFunction: columnMobile}}</mat-cell>
  </ng-container>
  <mat-row *matRowDef="let row; columns: ['mobile']; let i = index; let odd = odd; " [class.odd]="odd"></mat-row>
</mat-table>


<mat-paginator [ngClass]="{'hide-elem': elementList && elementList.data  && elementList.data.length == 0}"  [length]="elementLenght"
               [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="pageSize" ></mat-paginator>

<div *ngIf="elementList && elementList.data  && elementList.data.length == 0" class="empty-message">
  <mat-icon>warning</mat-icon>
  Lista vuota
</div>
