import { Routes } from "@angular/router";
import { CanLoadGuard } from "@overflo1/frame-frontend";
import { MachineDetailComponent } from "./components/machine-detail/machine-detail.component";

const guards: any[] = [CanLoadGuard];

export const MachineRoutes: Routes = [
    {
        path: 'frame/entity/machine/:entityId',
        component: MachineDetailComponent,
        canLoad: guards
    },
];