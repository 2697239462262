import { NgModule } from "@angular/core";
import { FrameSharedModule } from "../shared/frame-shared.module";

@NgModule({
  imports: [
    FrameSharedModule,
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
  ]
})
export class RoleModule { }
