<div class="wrapper" *ngIf="!selectedSettingOption">
    <div class="wrapper-head">Impostazioni</div>
    <div class="setting-options">
        <button
            mat-button
            class="setting-options-option"
            *ngFor="let setting of SettingOptionEnumTranslation | keyvalue"
            (click)="chooseSettingOption(setting.key)"
        >{{setting.value}}</button>
    </div>
</div>


<div class="wrapper" *ngIf="selectedSettingOption">
    <ng-container [ngSwitch]="selectedSettingOption">
        <ng-container *ngSwitchCase="SettingOptionEnum.width">
            <div class="wrapper-head">
              <span>{{SettingOptionEnumTranslation.width}} colonne</span>

              <span class="tip"
                    matTooltip="Uno slider completamente a sinistra indica l'utilizzo della larghezza di default impostata per quella colonna">
                <mat-icon class="tip-icon">help</mat-icon>
              </span>
            </div>
            <div class="item" *ngFor="let column of (columnList | orderColumnList)">
                <div>{{ column.name }}</div>
                <mat-slider
                    class="example-margin"
                    [min]="0"
                    [max]="500"
                    [step]="10"
                    discrete
                    [displayWith]="formatLabel"
                    (change)="onWidthChange(column)">
                    <input matSliderThumb [(ngModel)]="column.width" #slider>
                </mat-slider>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="SettingOptionEnum.order">
            <div class="wrapper-head">{{SettingOptionEnumTranslation.order}} colonne</div>
            <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
                <ng-container *ngFor="let column of columnList">
                    <div
                        class="item-drag-drop"
                        [cdkDragData]="column"
                        cdkDrag>{{column.name}}</div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

</div>

