import { Pipe, PipeTransform } from '@angular/core';
import { ColumnModel } from '../model/column.model';

@Pipe({
  standalone: true,
  name: 'orderColumnList'
})
export class OrderColumnListPipe implements PipeTransform {

  transform(columnList: ColumnModel[]): ColumnModel[] {
    return columnList.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      if (a.order) {
        return 1;
      }
      if (b.order) {
        return -1;
      }
      return 0;
    }).map((value, index) => ({
      ...value,
      order: index
    }));
  }

}
