export enum EnvironmentEnum {
    development = 'development',
    staging = 'staging',
    production = 'production',
}

export const environmentMenu = {
    development: {
        visible: true,
        background: 'cadetblue'
    },
    staging: {
        visible: true,
        background: 'chocolate'
    },
    production: {
        visible: false,
        background: 'transparent'
    }
}