import { Pipe, PipeTransform, } from '@angular/core';
import { getRoles, } from "../../../auth/selectors/auth.selector";
import { Store } from "@ngrx/store";
import { State as AppState } from '../../../auth/reducers/auth.reducers';
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ExtractDistinctPermissions } from "../../../role/role.utils";
import { RoleModel } from '../../../role/model/role.model';
import { FramePermissions } from '../../enums/frame.permissions';

@Pipe({
  name: 'hasPermission',
  standalone: true
})
export class HasPermissionPipe implements PipeTransform {

  constructor(private store: Store<AppState>) { }

  transform(requestedPermissions: string[], excludeAdmin = false, userRoles?: RoleModel[]): Observable<boolean> {
    return this.store.select(getRoles).pipe(map((loggedRoles: any) => {

      if (!requestedPermissions || requestedPermissions.length == 0) {
        return true;
      }

      const userDistinctPermissions = ExtractDistinctPermissions(userRoles || loggedRoles);

      if (userDistinctPermissions.includes(FramePermissions.SUPERADMIN)) {
        return true;
      }

      if (excludeAdmin) {
        return this.userHasAtLeastOnePermission(userDistinctPermissions, requestedPermissions);
      } else {
        // di default un ADMIN può vedere tutto a meno che excludeAdmin sia = true
        return (
          userDistinctPermissions.includes(FramePermissions.ADMIN) ||
          this.userHasAtLeastOnePermission(userDistinctPermissions, requestedPermissions)
        )
      }
    }));
  }

  userHasAtLeastOnePermission(userDistinctPermissions: string[], requestedPermissions: string[]): boolean {
    return (userDistinctPermissions && userDistinctPermissions.some((x) => requestedPermissions.includes(x)));
  }
}
