import { createAction, props } from '@ngrx/store';
import { LoginDto, LoginResponseDto } from '../../shared/models/login.model';

export const login = createAction('[Auth] Submit login', props<LoginDto>());

export const loginSuccess = createAction('[Auth] Login Success', props<LoginResponseDto>());

export const loginError = createAction('[Auth] Login Failure', props<{ error: string }>());

export const storeTokens = createAction('[Auth] Store tokens and user', props<LoginResponseDto>());

export const logout = createAction('[Auth] User logout');

export const logoutSuccess = createAction('[Auth] User logged out');
