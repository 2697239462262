export enum FramePermissions {
    SUPERADMIN = "superadmin",
    ADMIN = "admin",

    impersonate = "impersonate",

    roleRead = "role.read",
    roleCreate = "role.create",
    roleEdit = "role.edit",
    roleDelete = "role.delete",

    roleFunctionRead = "role-function.read",
    roleFunctionCreate = "role-function.create",
    roleFunctionEdit = "role-function.edit",
    roleFunctionDelete = "role-function.delete",

    rolePermissionRead = "role-permission.read",

    user = "user",
    userRead = "user.read",
    userCreate = "user.create",
    userEdit = "user.edit",
    userDelete = "user.delete",
}