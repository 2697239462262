<div class="input-container">
    <ng-template [ngIf]="readonly" [ngIfElse]="elseBlock">
        <mat-chip-set>
            <mat-chip *ngFor="let chip of chips" (removed)="removeChip(chip)">
                {{chip}}
            </mat-chip>
        </mat-chip-set>
    </ng-template>
    <ng-template #elseBlock>
        <mat-form-field [formGroup]="formGroup" appearance="outline">
            <input matInput
                [formControl]="inputFormControl"
                [attr.data-cy]="key"
                [id]="key"
                type="text"
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="defaultSeparatorKeyCodes"
                (matChipInputTokenEnd)="addChip($event)" />
        </mat-form-field>
        <mat-chip-grid #chipGrid>
            <mat-chip *ngFor="let chip of chips" (removed)="removeChip(chip)">
                {{chip}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-grid>
    </ng-template>
</div>
