import {SelectOptionModel} from "./select-option.model";
import {CustomValidator} from "./custom-validator-type.model";

export class BaseForm {
    value: any;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    options: SelectOptionModel[];
    optionsFunc?: (form: any) => SelectOptionModel[];
    searchWithDebounceFunc?: (searchValue: string) => Promise<SelectOptionModel[]>;
    debounceTime: number;
    if: (form: any) => boolean;
    readonly: boolean;
    fullWidth: boolean;
    passwordPattern: string;
    modifyValueOnValueChange?: (value: string) => string;
    validators?: CustomValidator[];
    help?: string;

    constructor(options: {
        value?: any;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: 'select' | 'multipleselect' | 'text' | 'textarea' | 'radio' | 'checkbox' | 'date' | 'datetime' | 'photos' | 'inputchips';
        type?: string;
        options?: SelectOptionModel[];
        optionsFunc?: (form: any) => SelectOptionModel[];
        searchWithDebounceFunc?: (searchValue: string) => Promise<SelectOptionModel[]>;
        debounceTime?: number;
        if?: (form: any) => boolean,
        readonly?: boolean,
        fullWidth?: boolean;
        passwordPattern?: string;
        modifyValueOnValueChange?: (value: string) => string;
        validators?: CustomValidator[];
        help?: string,
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.options = options.options || [];
        this.optionsFunc = options.optionsFunc;
        this.searchWithDebounceFunc = options.searchWithDebounceFunc;
        this.debounceTime = options.debounceTime || 300;
        this.if = options.if as (form: any) => boolean;
        this.readonly = options.readonly || false;
        this.fullWidth = options.fullWidth || false;
        this.passwordPattern = options.passwordPattern || '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}';
        this.modifyValueOnValueChange = options.modifyValueOnValueChange;
        this.validators = options.validators;
        this.help = options.help;
    }
}
