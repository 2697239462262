import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ListResponseModel } from '../../../shared/models/list-response.model';
import { ResponseModel } from '../../../shared/models/response.model';
import { HttpCallerService } from '../../../shared/services/http-caller/http-caller.service';
import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) {
    super(http, backendBasePath);
  }

  async createOne(user: UserModel) {
    return await this.post<ResponseModel<UserModel>>(`/user/`, user);
  }

  async updateOne(userId: number, user: Partial<UserModel>) {
    return await this.patch(`/user/${userId}`, user);
  }

  async deleteOne(userId: number) {
    return await this.delete(`/user/${userId}`);
  }

  async getOne(userId: number, query?: string) {
    return await this.get<UserModel>(`/user/${userId}?${query ?? ''}`);
  }

  async getMany(query?: string) {
    return await this.get<ListResponseModel<UserModel> | UserModel[]>(`/user?${query ?? ''}`);
  }

  async preference(preference: any) {
    return await this.post<ResponseModel<UserModel>>(`/user/preference`, preference);
  }
}
