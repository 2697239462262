import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'camelCaseToLabel',
})
export class CamelCaseToLabelPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(camelCaseString: string) {
    let label = '';
    for (let i = 0; i < camelCaseString.length; i++) {
      const character = camelCaseString.charAt(i);
      const nextCharacter = camelCaseString.charAt(i + 1);
      if (i === 0) {
        label += character.toUpperCase();
      } else {
        if (character == character.toLowerCase()) {
          label += character;
        } else {
          if (nextCharacter !== nextCharacter.toUpperCase()) {
            label += ` `;
          }
          label += `${character.toLowerCase()}`;
        }
      }
    }
    return label;
  }
}
