import { NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent, BaseForm } from '@overflo-srl/dynamic-form';
import { CommonFrameEntityFieldsEnum } from '../../../shared/enums/common-frame-entity-fields.enum';
import { DynamicFormControlType } from '../../../shared/enums/dynamic-form-control-type.enum';
import { FrameEntityType } from '../../../shared/enums/frame-entity-type.enum';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { frameEntityMapping } from '../../../shared/utils/frame-utils';
import { DynamicFormConfig } from '../../models/dynamic-form-config.model';
import { DynamicFormEntityConfig } from '../../models/dynamic-form-entity-config.model';
import { FrameEntityMetadataModel } from '../../models/frame-entity-metadata.model';
import { FrameEntityModel } from '../../models/frame-entity.model';
import { FrameEntityHttpService } from '../../services/frame-entity-http/frame-entity-http.service';

@Component({
  selector: 'lib-frame-entity-detail',
  standalone: true,
  imports: [
    NgIf,
    DynamicFormComponent,
  ],
  templateUrl: './frame-entity-detail.component.html',
  styleUrl: './frame-entity-detail.component.scss'
})
export class FrameEntityDetailComponent implements OnInit {
  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  @Output() save = new EventEmitter<Partial<FrameEntityModel>>();
  forms: BaseForm[] = [];
  submitting: boolean = false;
  @Input() entity?: FrameEntityModel;
  @Input() entityName?: string;
  @Input() entityId?: number;
  @Input() entityConfig?: DynamicFormEntityConfig;

  constructor(
    private frameEntityService: FrameEntityHttpService<any>,
    private translationService: TranslationService,
    @Inject('dynamicFormConfig') protected dynamicFormConfig: DynamicFormConfig,
  ) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.entityConfig && this.entityName) {
      this.entityConfig = this.dynamicFormConfig?.entityForms?.[this.entityName];
    }
    this.prepareDynamicForm();
  }

  async prepareDynamicForm() {
    if (!this.entityName) {
      return;
    }

    if (this.entityId && !isNaN(this.entityId)) {
      const entity = await this.frameEntityService.getOne(this.entityName, this.entityId);
      this.entity = entity;
    }

    const metadata = await this.frameEntityService.getMetadata(this.entityName);
    console.log('metadata', metadata);

    this.loadForms(metadata);
  }

  async loadForms(metadata: FrameEntityMetadataModel) {
    const generatedForms = this.getGeneratedFormsConfig(metadata);
    let configuredForms = this.applyFormsConfig(generatedForms);
    
    const allowedForms = this.entityConfig?.allowedForms;
    if (allowedForms?.length) {
      configuredForms = configuredForms.filter((form) => allowedForms.includes(form.key));
    } else {
      configuredForms = configuredForms.filter((form) => !Object.keys(CommonFrameEntityFieldsEnum).includes(form.key));
    }

    this.forms = configuredForms.map((form) => {
      return new BaseForm(form);
    });
  }

  getGeneratedFormsConfig(metadata: FrameEntityMetadataModel): Partial<BaseForm>[] {
    const formsConfig = [];

    for (const metadataItem of metadata.columns) {
      const propName = metadataItem.name;
      const propType = metadataItem.type;

      const mappedPropType = frameEntityMapping(propType);

      let controlType: any = 'text';
      let type = undefined;

      let required = !metadataItem.nullable;
      let readonly = !metadataItem.insertable;

      if (!readonly) {
        // entityConfig takes priority over dynamicFormConfig
        readonly = typeof this.entityConfig?.readonly == "boolean" ? this.entityConfig?.readonly : 
          typeof this.dynamicFormConfig?.readonly == "boolean" ? this.dynamicFormConfig?.readonly : false;
      }

      switch (mappedPropType) {
        case FrameEntityType.number:
          controlType = DynamicFormControlType.text;
          type = 'number';
          break;
        case FrameEntityType.date:
          controlType = DynamicFormControlType.date;
          break;
        case FrameEntityType.string:
          controlType = DynamicFormControlType.text;
          break;
        case FrameEntityType.boolean:
          controlType = DynamicFormControlType.checkbox;
          break;
      }

      const formConfig = {
        key: propName,
        value: this.entity ? this.entity[propName] : null,
        label: this.translationService.getTranslatedFieldName(this.entityName as string, propName),
        controlType: controlType,
        type: type,
        required: required,
        readonly: readonly,
        fullWidth: false,
      };
      formsConfig.push(formConfig);
    }
    return formsConfig;
  }

  applyFormsConfig(generatedForms: any[]) {
    if (!this.entityName) {
      return generatedForms;
    }

    const configForms = this.entityConfig?.forms;
    if (!this.entityConfig || !configForms?.length) {
      return generatedForms;
    }
    
    // using config forms only
    if (this.entityConfig.ignoreGeneratedForms) {
      return configForms as BaseForm[];
    }

    // updating generated forms with config forms
    for (const configForm of configForms as any[]) {
      const generatedFormIndex = generatedForms?.findIndex((_form) => _form.key === configForm.key);
      if (generatedFormIndex !== -1) {
        for (const formConfigPropName of Object.keys(configForm)) {
          generatedForms[generatedFormIndex][formConfigPropName] = configForm[formConfigPropName];
        }
      } else {
        generatedForms.push(configForm as BaseForm);
      }
    }
    return generatedForms as BaseForm[];
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async beforeSave(formResult: any) {
    if (!this.submitting || !this.entityName) {
      return;
    }
    const entityId = this.entity?.id as number;

    const data: Partial<FrameEntityModel> = {
      id: entityId || undefined,
      updated: new Date(),
      created: this.entity?.['created'] || new Date(),
      version: this.entity?.['version'],
    };

    Object.keys(formResult).forEach((key: string) => {  
      if (!Object.keys(CommonFrameEntityFieldsEnum).includes(key)) {
        data[key] = formResult[key];
      }
    });

    this.save.emit(data);
  }
}
