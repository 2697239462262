import {Component, Inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColumnModel} from '../model/column.model';
import {ViewableColumnListPipe} from '../pipes/viewable-column-list.pipe';

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    ViewableColumnListPipe,
  ],
  selector: 'lib-full-table-dialog',
  templateUrl: `./full-table-dialog.component.html`,
  styleUrls: ['./full-table-dialog.component.scss']
})
export class FullTableDialogComponent implements OnInit {

  cols: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<FullTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.cols = this.data.columnList.map(x => x.def);
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('400px');
  }
}

interface DialogData {
  element: any;
  columnList: ColumnModel[];
  actions: any;
}
