import { Pipe, PipeTransform } from '@angular/core';
import { HasPermissionPipe } from '../has-permission/has-permission.pipe';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'actionsMerge',
  standalone: true
})
export class ActionsMergePipe implements PipeTransform {

  constructor(
    private hasPermissionPipe: HasPermissionPipe,
  ) {
  }

  async transform(actions1: any[], actions2: any[], entityName?: string): Promise<any[]> {
    if (!entityName) {
      return [];
    }
    const actions = [...actions1, ...actions2 ];
    const allowedActions = [];
    for (const action of actions) {
      const permission = `${entityName}.${action.permission}`;
      if (await firstValueFrom(this.hasPermissionPipe.transform([permission]))) {
        allowedActions.push(action);
      }
    }
    return allowedActions;
  }

}
