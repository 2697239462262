<div class="component-wrapper">
    <div class="middle-title">
        <div class="middle-title-title">{{entityId ? 'Dettaglio' : 'Creazione'}} {{translatedEntityName}} {{entityId}}</div>
        <div class="middle-title-actions">
            <button mat-stroked-button [routerLink]="['/frame/entity/' + entityName]">Indietro</button>
            <button type="submit" mat-raised-button color="primary" (click)="confirm()">Salva</button>
        </div>
    </div>

    <lib-frame-entity-detail
        [entityName]="entityName"
        [entityId]="entityId"
        [entity]="entity"
        (save)="save($event)"
    >
        <ng-content></ng-content>
    </lib-frame-entity-detail>
</div>