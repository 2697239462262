<div class="component-wrapper">
  <div cdkDropList class="dd-container" (cdkDropListDropped)="drop($event)">
    @for (node of nodes; track node) {
      <div class="dd-node-card" cdkDrag>{{node.code}}</div>
    }
  </div>
  <div >
    <button mat-raised-button color="primary" (click)="onSave()">Salva</button>
  </div>
</div>
