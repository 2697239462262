import { Observable } from 'rxjs';
import { TreeItemModel } from '../../models/tree-item.model';
import { TreeRequestOptions } from '../../models/tree-request-options.model';

export abstract class TreeService<T extends TreeItemModel<T>> {

  constructor() { }

  abstract getChildren(id: string, options?: TreeRequestOptions): Observable<{children: T[]}>;

  abstract getRoots(): Observable<T[]>;

  abstract delete(id: string): Observable<void>;

  abstract create(node: Partial<T>): Observable<T>;

  abstract update(id: string, node: Partial<T>): Observable<T>;
}