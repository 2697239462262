import { NgModule } from '@angular/core';
import { RoleModule } from "../role/role.module";
import { UserRoutingModule } from './user-routing.module';
import { FrameSharedModule } from '../shared/frame-shared.module';

@NgModule({
  declarations: [
  ],
  imports: [
    UserRoutingModule,
    RoleModule,
    FrameSharedModule,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class UserModule {
}