<input
  autocomplete="off"
  class="mat-mdc-select-search-input mat-mdc-select-search-hidden"
  matInput
/>

<div
  [ngClass]="{ 'mat-mdc-select-search-inner-multiple': matSelect.multiple }"
  class="mat-mdc-select-search-inner mat-typography"
>
  <input
    #searchSelectInput
    (blur)="onBlur($any($event.target)?.value)"
    (input)="onInputChange($any($event.target)?.value)"
    (keydown)="_handleKeydown($event)"
    (keyup)="_handleKeyup($event)"
    [placeholder]="placeholderLabel"
    autocomplete="off"
    class="mat-mdc-select-search-input autocomplete"
    matInput
  />
  <button
    (click)="_reset(true)"
    *ngIf="value"
    aria-label="Clear"
    class="mat-mdc-select-search-clear"
    mat-button
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<!--
<div *ngIf="noEntriesFoundLabel && value && _options?.length === 0"
     class="mat-select-search-no-entries-found mat-typography">
  {{noEntriesFoundLabel}}
</div>
-->
