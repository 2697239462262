import {RoleModel} from "./model/role.model";
import {RolePermissionModel} from "./model/role-permission.model";
import {RoleFunctionModel} from "./model/role-function.model";

export function ExtractDistinctPermissions (newRoles: RoleModel[]) : string[] {
  if(!newRoles || newRoles.length == 0) {
    return [];
  }

  let rolePermissions : RolePermissionModel[] = [];
  let roleFunctions : RoleFunctionModel[] = [];

  for(const newRole of newRoles) {
    roleFunctions = roleFunctions.concat(newRole.functions);
  }

  for(const roleFunction of roleFunctions) {
    rolePermissions = [...rolePermissions, ...roleFunction.permissions];
  }

  const permissions = rolePermissions.map(rolePermission => rolePermission.name);
  return [...new Set(permissions)];
}
