<div class="container">
  <mat-toolbar class="toolbar">
    <button class="btn-menu-toggle" mat-icon-button (click)="snav.toggle()" data-cy="menuButton">
      <mat-icon>menu</mat-icon>
    </button>

    <img src="/img/logo.png" />
  </mat-toolbar>

  <div 
    class="environment" 
    *ngIf="environmentMenu[environment]?.visible"
    [ngStyle]="{'background-color': environmentMenu[environment].background}"
  >
    {{environment}}
  </div>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav" #snav fixedTopGap="56" [autoFocus]="false" [mode]="isMobile.matches ? 'over' : 'side'">
      <div class="user-info">
        <mat-icon class="user-info-icon">person</mat-icon>
        <div class="user-info-denomination">{{ user?.username || user?.email }}</div>
      </div>
      <mat-nav-list class="main-menu">
        <ng-content></ng-content>
      </mat-nav-list>
      <mat-nav-list class="user-menu">
        @if ([FramePermissions.user] | hasPermission | async) {
          <mat-list-item routerLink="/users" routerLinkActive="router-link-active">
            <span class="list-item bigger-font"><mat-icon>person_list</mat-icon>Utenti</span>
          </mat-list-item>
        }
        <mat-list-item routerLink="/auth/edit-password" routerLinkActive="router-link-active">
          <span class="list-item bigger-font"><mat-icon>lock_person</mat-icon>Account</span>
        </mat-list-item>
        <mat-list-item (click)="logout()" data-cy="logoutButton">
          <span class="list-item bigger-font"><mat-icon>logout</mat-icon>Logout</span>
        </mat-list-item>
      </mat-nav-list>

      <div class="sidenav-footer">
        <app-footer [showManualLink]="false"></app-footer>
        <div class="sidenav-footer-versions">Frontend: v{{ frontendVersion }} &nbsp;&nbsp;&nbsp; Backend: v{{ backendVersion }}</div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <div class="sidenav-content-overlay"></div>
      <div style="min-height: 100%" fxLayout="column">
        <div fxFlex="1">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>