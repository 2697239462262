import { Pipe, PipeTransform } from '@angular/core';
import {ColumnModel} from "../model/column.model";
import {FilterOptionsEnum} from '../enums/filter-options.enum';

@Pipe({
  name: 'mustSpecifyValue',
  standalone: true,
})
export class MustSpecifyValuePipe implements PipeTransform {
  transform(value: FilterOptionsEnum) {
    const optionsWithValueToSpecify: FilterOptionsEnum[] = [FilterOptionsEnum.SPECIFY_VALUE, FilterOptionsEnum.NOT_EQUAL]
    return optionsWithValueToSpecify.includes(value);
  }
}
