import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  shareReplay,
  lastValueFrom,
  expand,
  firstValueFrom
} from "rxjs";
import {TreeItemModel} from "../models/tree-item.model";
import {TreeState} from "../models/tree-state.model";
import {TreeTransformedData} from "../services/tree-transformed-data/tree-transformed-data";
import {TreeService} from "../services/tree/tree.service";
import {TransformFn} from "../utils/tree-types";
import {LoadingStateEnum} from "../enums/loading-state.enum";
import {TreeRawDataModel} from "../models/tree-raw-data.model";

export class TreeDataStore<T extends TreeItemModel<T>> {

  constructor(
    private treeService: TreeService<T>
  ) {
  }

  private _state = new BehaviorSubject<TreeState>({
    rootIds: [],
    rootsLoading: LoadingStateEnum.INIT,
    allData: new Map(),
    dataLoading: new Map(),
  });

  private readonly _rootIds = this.select(state => state.rootIds);
  private readonly _allData = this.select(state => state.allData);
  private readonly _loadingData = this.select(state => state.dataLoading);
  private readonly _rootsLoadingState = this.select(state => state.rootsLoading);
  readonly areRootsLoading = this.select(
    this._rootIds,
    this._loadingData,
    this._rootsLoadingState,
    (rootIds, loading, rootsLoading) =>
      rootsLoading !== LoadingStateEnum.LOADED || rootIds.some(id => loading.get(id) !== LoadingStateEnum.LOADED),
  );
  readonly roots = this.select(
    this.areRootsLoading,
    this._rootIds,
    this._allData,
    (rootsLoading, rootIds, data) => {
      if (rootsLoading) {
        return [];
      }
      return this._getDataByIds(rootIds, data);
    },
  );

  getChildren(parentId: string) {
    return this.select(this._allData, this._loadingData, (data, loading) => {
      const parentData = data.get(parentId);

      if (parentData?.childrenLoading !== LoadingStateEnum.LOADED) {
        return [];
      }
      const childIds = (parentData.childrenIds as any).map((c: T) => c.id) ?? [];
      if (childIds.some((id: string) => loading.get(id) !== LoadingStateEnum.LOADED)) {
        return [];
      }

      return this._getDataByIds(childIds, data);
    });
  }

  getSiblings(nodeId: string) {
    return this.select(this._allData, this._loadingData, (data, loading) => {
      const nodeSonId: any | null = data.get(nodeId)?.parentId
      const parentData = data.get(nodeSonId);

      if (parentData?.childrenLoading !== LoadingStateEnum.LOADED) {
        return [];
      }
      const childIds = (parentData.childrenIds as any).map((c: T) => c.id) ?? [];

      if (childIds.some((id: string) => loading.get(id) !== LoadingStateEnum.LOADED)) {
        return [];
      }
      return this._getDataByIds(childIds, data)

    });
  }

  loadRoots() {
    this._setRootsLoading();
    this.treeService.getRoots().subscribe(roots => {
      this._setRoots(roots);
    });
  }

  loadChildren(parentId: string) {
    this._setChildrenLoading(parentId);
    this.treeService.getChildren(parentId).subscribe((data: { children: T[] }) => {
      this._addLoadedData(parentId, data.children);
    });
  }

  private _setRootsLoading() {
    this._state.next({
      ...this._state.value,
      rootsLoading: LoadingStateEnum.LOADING,
    });
  }

  private _setRoots(roots: T[]) {
    const currentState = this._state.value;

    this._state.next({
      ...currentState,
      rootIds: roots.map(root => root.id),
      rootsLoading: LoadingStateEnum.LOADED,
      ...this._addData(currentState, roots),
    });
  }


  private _setChildrenLoading(parentId: string) {
    const currentState = this._state.value;
    const parentData = currentState.allData.get(parentId);

    this._state.next({
      ...currentState,
      allData: new Map([
        ...currentState.allData,
        ...(parentData ? ([[parentId, {...parentData, childrenLoading: LoadingStateEnum.LOADING}]] as const) : []),
      ]),
      dataLoading: new Map([
        ...currentState.dataLoading,
        ...(parentData?.childrenIds?.map(childId => [childId, LoadingStateEnum.LOADING] as const) ?? []),
      ]),
    });
  }

  private _addLoadedData(parentId: string, childData: T[]) {
    const currentState = this._state.value;
    this._state.next({
      ...currentState,
      ...this._addData(currentState, childData, parentId),
    });
  }

  private _addData(
    {allData, dataLoading}: TreeState,
    data: T[],
    parentId?: string,
  ): Pick<TreeState, 'allData' | 'dataLoading'> {
    const parentData = parentId && allData.get(parentId);
    let fatherChildrenOrder: number[] = [];

    if (!parentData || typeof parentData !== 'object') {
    } else {
      fatherChildrenOrder = parentData.childrenOrder || [];
    }

    // Calcola i figli in ordine
    const orderedChildren = fatherChildrenOrder
      .map(id => data.find(child => Number(child.id) === id))
      .filter(child => child !== undefined);

    // Se non ci sono figli ordinati, usa l'ordine originale
    if (parentData) {
      if (orderedChildren.length === 0) {
        parentData.childrenIds = data.map(child => child) as any;
      } else {
        parentData.childrenIds = orderedChildren as any;
      }
      allData.set(parentId, parentData);
    }

    const allChildren = data.flatMap(datum => {
      return datum.children ?? [];
    }).filter(child => child !== undefined);

    return {
      allData: new Map([
        ...allData as any,
        ...data.map(node => {
          const {key, value} = this.mapNode(node, parentId);
          return [key, value];
        }),
        ...(parentData ? ([[parentId, {...parentData, childrenLoading: LoadingStateEnum.LOADED}]] as const) : []),
      ]),
      dataLoading: new Map([
        ...dataLoading as any,
        ...data.map(datum => [datum.id, LoadingStateEnum.LOADED] as const),
        ...allChildren.map(childId => [childId, LoadingStateEnum.INIT] as const),
      ]),
    };
  }

  mapNode(node: T, parentId?: string) {
    return {
      key: node.id,
      value: {
        ...node,
        id: node.id,
        parentId,
        childrenIds: node.children?.map((c) => c.id) || [],
        childrenLoading: LoadingStateEnum.INIT as const,
      }
    }
  }

  private _getDataByIds(ids: string[], data: TreeState['allData']) {
    return ids
      .map(id => data.get(id))
      .filter(<T>(item: T | undefined): item is T => !!item)
      .map(datum => new TreeTransformedData(datum));
  }

  select<T extends readonly Observable<unknown>[], U>(
    ...sourcesAndTransform: [...T, TransformFn<T, U>]
  ) {
    const sources = sourcesAndTransform.slice(0, -1) as unknown as T;
    const transformFn = sourcesAndTransform[sourcesAndTransform.length - 1] as TransformFn<T, U>;

    return combineLatest([...sources, this._state]).pipe(
      map(args => transformFn(...(args as any))),
      shareReplay({refCount: true, bufferSize: 1}),
    );
  }

  async delete(id: string) {
    await lastValueFrom(this.treeService.delete(id));
    this._state.value.allData.delete(id);
    this.refreshTreeState();
  }

  async create(node: Partial<T>) {
    const newNode = await lastValueFrom(this.treeService.create(node));
    const parentId = node.parent?.id;

    if (parentId) {
      const parentData = this._state.value.allData.get(parentId);
      if (parentData) {
        parentData.childrenIds = [...(parentData.childrenIds || []), newNode.id];
        parentData.childrenOrder = [...(parentData.childrenOrder || []), Number(newNode.id)];
        this._state.value.allData.set(parentId, parentData);
      }
    }
    this.reloadNode(parentId);
  }

  async update(id: string, node: Partial<T>, reorder?: boolean) {
    await lastValueFrom(this.treeService.update(id, node));
    const parentId = this._state.value.allData.get(id)?.parentId;
    const newOrder: number[] = node.childrenOrder as number[]

    if (reorder) {
        const parentData = this._state.value.allData.get(id) as TreeRawDataModel;
        //aggiornamento stato locale
        this._state.next({
          ...this._state.value,
          allData: new Map(this._state.value.allData).set(id, {
            ...parentData,
            childrenOrder: newOrder,
          }),
        });
      this.reloadNode(id);
    } else {
      this.reloadNode(parentId);
    }
  }

  reloadNode(id?: string) {
    if (id) {
      this.loadChildren(id);
    } else {
      this.loadRoots();
    }
  }

  refreshTreeState() {
    this._state.next({
      ...this._state.value,
    });
  }
}
