export enum OperatorsEnum{
  NOT_NULL = 'not null',
  NULL = 'null',
  EQUALS = "=",
  NOT_EQUALS = "!=",
  GREATER_THAN = ">",
  LOWER_THAN = "<",
  GREATER_THAN_EQUALS = ">=",
  LOWER_THAN_EQUALS = "<=",
}
