import {Injectable} from '@angular/core';
import FileSaver from "file-saver";
import * as xlsx from "xlsx";

export class ExportingColumn {
  def!: string;
  name!: string;
}

export class Worksheet {
  name?: string;
  data?: xlsx.WorkSheet
}

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  maxColumnHeadersWidth: number = 10;

  export(elements: any[], columnList: ExportingColumn[], fileName?: string) {
    this.doExport(elements, columnList, fileName);
  }

  /**
   * @param elements: array of objects to export (es. UserModel[])
   * @param columnsWithLabels
   */
  formatData(elements: any[], columnsWithLabels: ExportingColumn[]) {
    const rows = [];
    for (const element of elements) {
      const row: any = {}
      for (const column of columnsWithLabels) {
        this.maxColumnHeadersWidth = column.name.length > this.maxColumnHeadersWidth ? column.name.length : this.maxColumnHeadersWidth;
        row[column.name] = element[column.def];
      }
      rows.push(row)
    }
    return rows;
  }

  dataToWorksheet(elements: any[], columnList: ExportingColumn[]) {
    this.maxColumnHeadersWidth = 10;
    columnList = columnList.map(column => { return { ...column, name: column.name.toUpperCase() } });
    const rows = this.formatData(elements, columnList);

    const worksheet = xlsx.utils.json_to_sheet(rows);
    worksheet["!cols"] = columnList.map(column => {
      return {wch: this.maxColumnHeadersWidth}
    }); // set columns header width
    return worksheet;
  }

  worksheetsToWorkbook(worksheets: Worksheet[]) {
    const sheets: any = {};
    for (const worksheet of worksheets) {
      if (worksheet.name) {
        sheets[worksheet.name] = worksheet.data;
      }
    }
    const sheetNames = worksheets.map((worksheet) => worksheet.name);
    return {Sheets: sheets, SheetNames: sheetNames};
  }

  saveWorkbookAsExcelFile(workbook: xlsx.WorkBook, fileName: string) {
    const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  doExport(rows: any[], columnList: ExportingColumn[], fileName = '') {
    const worksheet = this.dataToWorksheet(rows, columnList);
    const workbook: any = this.worksheetsToWorkbook([{
      name: 'data',
      data: worksheet
    }]);
    this.saveWorkbookAsExcelFile(workbook, fileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + "export_" + new Date().getTime() + EXCEL_EXTENSION);
  }
}
