import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducers';
import dayjs from 'dayjs';

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const isLoggedIn = createSelector(selectAuthState, auth => auth.token && dayjs(auth.token.expirationDate).isAfter(dayjs()));

export const canNavigate = createSelector(selectAuthState, auth => (auth.token && dayjs(auth.token.expirationDate).isAfter(dayjs())));

export const getToken = createSelector(selectAuthState, auth => (auth.token ? auth.token.jwt : ''));

export const getRoles = createSelector(selectAuthState, auth => (auth.user ? auth.user.roles : []));

export const getAuthState = createSelector(selectAuthState, auth => auth);

export const getMe = createSelector(selectAuthState, auth => auth.user);

export const getErrorMessage = createSelector(selectAuthState, auth => auth.error);
