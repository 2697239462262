import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getErrorMessage, isLoggedIn } from '../../selectors/auth.selector';
import { login } from '../../actions/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { AppState } from '../../../shared/reducers';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  providers: [
    AsyncPipe,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  forgottenPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  isLoading = false;
  forgottenPasswordEnabled: boolean = false;
  forgottenPasswordEmailInvalid: boolean = false;

  error$: Observable<string | undefined> | undefined;
  private readonly onDestroy = new Subject<void>();

  constructor(
    private store: Store<AppState>, 
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) {
  }

  async ngOnInit() {
    this.error$ = this.store.select(getErrorMessage);

    this.store
      .select(isLoggedIn)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.isLoading = false;
        }
      });
  }

  login() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.store.dispatch(
        login({
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
        })
      );
    }
  }

  async requestPasswordReset() {
    this.forgottenPasswordForm.markAllAsTouched();
    if (Object.keys(this.forgottenPasswordForm.controls?.['email'].errors || {})?.length) {
      this.forgottenPasswordEmailInvalid = true;
    } else {
      this.forgottenPasswordEmailInvalid = false;
      await this.authService.requestPasswordReset(this.forgottenPasswordForm.controls?.['email'].value);
      this.snackBarService.openInfoSnackBar(`L'email di ripristino verrà inviata in alcuni secondi`);
      this.forgottenPasswordForm.reset();
      this.toggleForgottenPasswordEnabled();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  toggleForgottenPasswordEnabled() {
    this.forgottenPasswordEnabled = !this.forgottenPasswordEnabled;
  }
}
