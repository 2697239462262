import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslationLanguagesEnum, FrameModule } from '@overflo1/frame-frontend';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MachineDynamicFormConfig, MachineFullTableConfig, MachineTranslations } from './frame-entity-config/machine';
import { RequestDynamicFormConfig, RequestFullTableConfig, RequestTranslations } from './frame-entity-config/request';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FrameModule.forRoot({
      environment: environment.ENVIRONMENT,
      dateFormat: environment.DATE_FORMAT,
      dateTimeFormat: environment.DATE_TIME_FORMAT,
      locale: environment.LOCALE,
      httpConfig: {
        basePath: environment.backendBasePath
      },
      dynamicFormConfig: {
        readonly: true,
        entityForms: {
          machine: MachineDynamicFormConfig,
          request: RequestDynamicFormConfig
        }
      },
      fullTableConfig: {
        entityColumns: {
          machine: MachineFullTableConfig,
          request: RequestFullTableConfig
        }
      },
      translationConfig: {
        selectedLanguage: TranslationLanguagesEnum.it,
        entityTranslations: {
          it: {
            machine: MachineTranslations,
            request: RequestTranslations
          }
        }
      }
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: environment.DATE_LOCALE },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
