import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { EnabledUserGuard } from '../shared/guards/enabled-user/enabled-user.guard';
import { RolesGuard } from '../shared/guards/roles/roles.guard';
import { PasswordEditComponent } from './components/password-edit/password-edit.component';

const guards: any[] = [EnabledUserGuard];

const routes: Routes = [
  {
    path: 'edit-password',
    component: PasswordEditComponent,
    canActivate: [...guards, RolesGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class AuthRoutingModule { }
