import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MediaMatcher } from "@angular/cdk/layout";
import { MatSidenav } from '@angular/material/sidenav';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { UserModel } from '../../../user/models/user.model';
import { AppState } from '../../reducers';
import { VersionService } from '../../services/version/version.service';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { logout } from '../../../auth/actions/auth.actions';
import { FramePermissions } from '../../enums/frame.permissions';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HasPermissionPipe } from '../../pipes/has-permission/has-permission.pipe';
import {MatSidenavModule} from '@angular/material/sidenav';
import { FooterComponent } from '../footer/footer.component';
import {MatToolbarModule} from '@angular/material/toolbar'; 
import { MatButtonModule } from '@angular/material/button';
import { EnvironmentEnum, environmentMenu } from '../../enums/environment.enum';

@Component({
  selector: 'lib-frame-menu',
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    HasPermissionPipe,
    AsyncPipe,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    FooterComponent,
    MatToolbarModule,
    RouterOutlet,
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './frame-menu.component.html',
  styleUrls: ['./frame-menu.component.scss'],
  providers: [
    HasPermissionPipe,
    AsyncPipe,
  ],
})
export class FrameMenuComponent implements OnInit {

  @ViewChild("snav") sidenav!: MatSidenav;
  @Input() frontendVersion?: string;
  readonly FramePermissions = FramePermissions;
  readonly EnvironmentEnum = EnvironmentEnum;
  readonly environmentMenu = environmentMenu;
  backendVersion: any;
  user?: UserModel;
  isMobile: MediaQueryList;

  constructor(
    @Inject('environment') public environment: EnvironmentEnum,
    private store: Store<AppState>,
    private versionService: VersionService,
    private readonly mediaMatcher: MediaMatcher,
    private readonly router: Router,
    private authService: AuthService,
  ) {
    this.isMobile = this.mediaMatcher.matchMedia("(max-width: 599px)");
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getMe();
    this.backendVersion = (await this.versionService.getBackendVersion())?.version;
    this.router.events.subscribe(async event => {
      // close sidenav on routing
      if (this.sidenav) {
        if (this.isMobile.matches) {
          await this.sidenav.close();
        }
      }
    });
  }

  logout() {
    this.store.dispatch(logout());
  }
}
