
<div #injectedDynamicForm><ng-content></ng-content></div> 
<span *ngIf="!injectedDynamicForm.children.length">
    <lib-dynamic-form 
        #dynamicForm 
        [forms]="forms" 
        (send)="beforeSave($event)" 
        [showButtonAndEmit]="false" 
        [showConfirmationDialog]="false"
        *ngIf="entityName"
    ></lib-dynamic-form>
</span>