import {Pipe, PipeTransform} from '@angular/core';
import {ColumnModel} from '../model/column.model';
import {AbstractControl, ɵFormGroupRawValue, ɵGetProperty, ɵTypedOrUntyped} from '@angular/forms';
import {FilterOptionModel} from '../model/filter-option.model';

export type FormColumn =  AbstractControl<ɵGetProperty<ɵTypedOrUntyped<any, ɵFormGroupRawValue<any>, any>, "column">> | null;
@Pipe({
  standalone: true,
  name: 'getColumnFilterOptions'
})
export class GetColumnFilterOptionsPipe implements PipeTransform {

  transform(columnList: ColumnModel[], filterForm: FormColumn, colName?: string): FilterOptionModel[] | undefined {
    const column = colName ? columnList.find((col) => col.name === colName) : this.getColumnForFilter(columnList, filterForm);
    return column?.filterOptions;
  }

  private getColumnForFilter(columnList: ColumnModel[], filterForm: FormColumn){
    return columnList.find((col) => col.name === filterForm?.value?.column);
  }
}
