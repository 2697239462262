import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private readonly snackBar: MatSnackBar) {
  }

  openErrorSnackBar(message: string) {
    this.openSnackBar(message, 10000,'error-snackbar');
  }

  openInfoSnackBar(message: string) {
    this.openSnackBar(message, 3000, 'custom-snackbar');
  }

  private openSnackBar(message: string, duration_ms:number, panelClass: string){
    this.snackBar.open(message, 'OK', {
      duration: duration_ms,
      panelClass: [panelClass]
    });
  }
}