<div class="component-wrapper">
  <div class="middle-title">
    @if (data.user) {
      <div>Modifica utente ID: {{data.user.id}}</div>
    }
    @if (!data.user) {
      <div>Creazione utente</div>
    }
    <button type="submit" mat-raised-button color="primary" (click)="confirm()" data-cy="saveButton">Salva</button>
  </div>

  <lib-dynamic-form #dynamicForm [forms]="forms" (send)="save($event)" [showButtonAndEmit]="false" [showConfirmationDialog]="false"></lib-dynamic-form>

  @if (selectedRoles) {
    <div class="selected-roles">
      <div class="middle-title">Funzioni</div>
      <div class="fields-fill">
        @for (roleFunction of selectedRoles | roleFunctions; track roleFunction) {
          <div class="field function-field">
            {{roleFunction.name}}
          </div>
        }
        @if (!selectedRoles.length) {
          <div>Nessuna</div>
        }
      </div>
    </div>
  }
</div>