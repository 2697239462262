import { Inject, Injectable } from '@angular/core';
import { TranslationConfig } from '../../../frame-entity/models/translation-config.model';
import { TranslationLanguagesEnum } from '../../enums/translation-languages.enum';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    @Inject('translationConfig') protected translationConfig: TranslationConfig,
  ) { }

  getSelectedLanguage() {
    return this.translationConfig.selectedLanguage || TranslationLanguagesEnum.it;
  }

  getTranslations(entityName: string) {
    const language = this.getSelectedLanguage();
    return this.translationConfig?.entityTranslations?.[language]?.[entityName];
  }

  getTranslatedEntityName(entityName: string) {
    const translations = this.getTranslations(entityName);
    return translations?.entityName || entityName;
  }

  getTranslatedFieldName(entityName: string, fieldName: string) {
    const translations = this.getTranslations(entityName);
    return translations?.fields?.[fieldName] || fieldName;
  }
}
