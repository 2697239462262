export enum FrameEntityMetadataType {
    int = 'int',
    int4 = 'int4',
    decimal = 'decimal',
    float = 'float',
    doublePrecision = 'double precision',
    number = 'number',
    integer = 'integer',

    timetz = 'timetz',
    timestamptz = 'timestamptz',
    timestamp = 'timestamp',
    timestampWithoutTimeZone = 'timestamp without time zone',
    timestampWithTimeZone = 'timestamp with time zone',
    date = 'date',
    time = 'time',
    timeWithoutTimeZone = 'time without time zone',
    timeWithTimeZone = 'time with time zone',
    fDate = 'function Date() { [native code] }',

    varchar = 'varchar',
    char = 'char',
    text = 'text',
    json = 'json',
    jsonb = 'jsonb',
    string = 'string',

    bool = 'bool',
    boolean = 'boolean',
}