import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ColumnModel} from "../model/column.model";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {PageEvent} from "@angular/material/paginator";
import {ChipListItem} from "../model/chip-list-item.model";
import {QueryParamsEnum} from "../enums/query-params.enum";
import {EventSourceTypeEnum} from "../enums/event-source-type.enum";
import {convertBooleanParamsValues, refactorParams} from "../shared/utils/utils";

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
  }

  // handles filter compatibility with the url query parameters when the page and table are loaded
  async handleFilters(chipList: ChipListItem[], columnList: ColumnModel[]) {
    const urlParams = this.getQueryParams();
    const paramsNames = Object.keys(urlParams);
    const filterParamsSearch = paramsNames.filter(params =>
      params.includes(QueryParamsEnum.VALUE_PARAM) ||
      params.includes(QueryParamsEnum.START_PARAM) ||
      params.includes(QueryParamsEnum.END_PARAM)
    );
    const filterParams = refactorParams(filterParamsSearch);

    // if there are no filters in the query parameters
    if (!filterParams.length) {
      // removes all filters including default ones if the page, sort or quick search are defined but the filter is empty on load
      if ((urlParams.hasOwnProperty(QueryParamsEnum.PAGE_SIZE) || urlParams.hasOwnProperty(QueryParamsEnum.SORT_ACTIVE)
        || urlParams.hasOwnProperty(QueryParamsEnum.QUICK_SEARCH))
      )
        chipList = [];
    }
    else {
      // adds or changes filters based on what is in the url query parameters
      chipList = [];
      for (const param of filterParams) {
        const column = columnList.find(column => param.toLowerCase() == column.def.toLowerCase());

        if (column) {
          const start = urlParams[column.def + QueryParamsEnum.START_PARAM] ?? null;
          const end = urlParams[column.def + QueryParamsEnum.END_PARAM] ?? null;
          const operation = urlParams[column.def + QueryParamsEnum.OPERATION_PARAM];
          const value = convertBooleanParamsValues(urlParams[column.def + QueryParamsEnum.VALUE_PARAM] ?? null, column);
          const chipToSave = {
            column: column.name, value, start, end, operation: operation, isExactValue: operation == '='
          };
          chipList.push(chipToSave);
        }
      }
    }
    return chipList;
  }

  // creates the url query parameters based on the filters
  async createFilterQuery(origin: string, chipList: ChipListItem[], columnList: ColumnModel[], sort: MatSort, pageSize?: number, quickSearchText?: string) {
    const queryRes: {[index: string]:any} = {};
    const urlParams = this.getQueryParams();

    // filters
    for (const chip of chipList) {
      const column = columnList.filter((el) => el.name == chip.column);
      if (column.length) {
        if (chip.operation) queryRes[column[0].def + QueryParamsEnum.OPERATION_PARAM] = chip.operation;
        if (chip.value != null) queryRes[column[0].def + QueryParamsEnum.VALUE_PARAM] = chip.value;
        if (chip.start) queryRes[column[0].def + QueryParamsEnum.START_PARAM] = chip.start;
        if (chip.end) queryRes[column[0].def + QueryParamsEnum.END_PARAM] = chip.end;
      }
    }

    // sort
    this.setSortFromQueryParams(queryRes, origin, sort);

    // quick search
    this.setQuickSearchFromQueryParams(queryRes, origin, quickSearchText);

    // pagination
    queryRes[QueryParamsEnum.PAGE_INDEX] = (urlParams.hasOwnProperty(QueryParamsEnum.PAGE_INDEX) && origin != EventSourceTypeEnum.EVENT) ? urlParams[QueryParamsEnum.PAGE_INDEX] : 1;
    queryRes[QueryParamsEnum.PAGE_SIZE] = (urlParams.hasOwnProperty(QueryParamsEnum.PAGE_SIZE)) ? urlParams[QueryParamsEnum.PAGE_SIZE] : pageSize;

    return queryRes;
  }

  private setSortFromQueryParams(queryRes: {[index: string]:any}, origin: string, sort: MatSort) {
    const urlParams = this.getQueryParams();

    if (urlParams.hasOwnProperty(QueryParamsEnum.SORT_ACTIVE)) {
      queryRes[QueryParamsEnum.SORT_ACTIVE] = urlParams[QueryParamsEnum.SORT_ACTIVE];
      queryRes[QueryParamsEnum.SORT_DIRECTION] = urlParams[QueryParamsEnum.SORT_DIRECTION];

      if (origin != EventSourceTypeEnum.EVENT) {
        sort.active = queryRes[QueryParamsEnum.SORT_ACTIVE];
        sort.direction = queryRes[QueryParamsEnum.SORT_DIRECTION];

        // arrow animation
        const activeSortHeader = <MatSortHeader>sort?.sortables.get(queryRes[QueryParamsEnum.SORT_ACTIVE]);
        activeSortHeader._setAnimationTransitionState({
          fromState: sort.direction,
          toState: 'active',
        });
      }
    }
  }

  private setQuickSearchFromQueryParams(queryRes: {[index: string]:any}, origin: string, quickSearchText?: string) {
    const urlParams = this.getQueryParams();

    if (urlParams.hasOwnProperty(QueryParamsEnum.QUICK_SEARCH) && origin != EventSourceTypeEnum.EVENT)
      queryRes[QueryParamsEnum.QUICK_SEARCH] = urlParams[QueryParamsEnum.QUICK_SEARCH];

    if (quickSearchText?.trim())
      queryRes[QueryParamsEnum.QUICK_SEARCH] = quickSearchText?.trim();
  }


  // applies the sort changes to the url query parameters
  applySortChangesToUrlQueryParams(sortChange: MatSort, pageSize?: number): void {
    const oldQueryParams = this.getQueryParams();

    const sortingQueryParams = {
      sort_active: sortChange.active,
      sort_direction: sortChange.direction,
      page_index: (oldQueryParams[QueryParamsEnum.SORT_ACTIVE] == sortChange.active
        && oldQueryParams[QueryParamsEnum.SORT_DIRECTION] == sortChange.direction && oldQueryParams.hasOwnProperty(QueryParamsEnum.PAGE_INDEX))
        ? oldQueryParams[QueryParamsEnum.PAGE_INDEX] : 1,
      page_size: oldQueryParams.hasOwnProperty(QueryParamsEnum.PAGE_SIZE) ? oldQueryParams[QueryParamsEnum.PAGE_SIZE] : pageSize
    };

    this.addQueryParamsToUrl(sortingQueryParams);
  }

  // applies the pagination changes to the url query parameters
  applyPageChangesToUrlQueryParams(page: PageEvent): void {
    const PaginationQueryParams = {
      page_index: page.pageIndex + 1,
      page_size: page.pageSize
    };

    this.addQueryParamsToUrl(PaginationQueryParams);
  }

  addQueryParamsToUrl(queryParams: Params | null, merge = true, origin = 'event') {
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: merge ? 'merge' : null,
        replaceUrl: origin == EventSourceTypeEnum.ONLOAD
      }
    );
  }

  // retrieves route parameters
  getQueryParams() {
    return this._activatedRoute.snapshot.queryParams;
  }
}
