<div class="component-wrapper">
  <mat-tab-group [(selectedIndex)]="tabIndex" #tabGroup>
    <mat-tab label="Utenti">
      <div class="middle-title">
        <div>Utenti</div>
        @if ([FramePermissions.userCreate] | hasPermission | async) {
          <button mat-raised-button color="primary" (click)="openUserViewDialog()" data-cy="createUserButton">Aggiungi utente</button>
        }
      </div>
      @if (userListEnabled && columnList) {
        <lib-full-table [actions]="actions" [search]="search" [join]="join" [columnList]="columnList" [quickSearchColumns]="quickSearchColumns" [pageSize]="10" path="user"></lib-full-table>
      }
    </mat-tab>
    @if ([FramePermissions.roleRead] | hasPermission | async) {
      <mat-tab label="Ruoli">
        <div class="middle-title">
          <div>Ruoli</div>
          @if ([FramePermissions.roleCreate] | hasPermission | async) {
            <button mat-raised-button color="primary" (click)="openRoleEditDialog()">Aggiungi ruolo</button>
          }
        </div>
        <app-role-list></app-role-list>
      </mat-tab>
    }
    @if ([FramePermissions.roleFunctionRead] | hasPermission | async) {
      <mat-tab label="Funzioni">
        <div class="middle-title">
          <div>Funzioni</div>
          @if ([FramePermissions.roleFunctionCreate] | hasPermission | async) {
            <button mat-raised-button color="primary" (click)="openFunctionEditDialog()">Aggiungi funzione</button>
          }
        </div>
        <app-function-list></app-function-list>
      </mat-tab>
    }
  </mat-tab-group>
</div>
