<lib-frame-menu [frontendVersion]="frontendVersion">
  <mat-nav-list class="main-menu">
    <mat-list-item routerLink="/home" routerLinkActive="router-link-active">
      <span class="list-item bigger-font"><mat-icon>dashboard</mat-icon>Dashboard</span>
    </mat-list-item>
    @if ([AlmaboxPermissions.machine] | hasPermission | async) {
      <mat-list-item routerLink="/frame/entity/machine" routerLinkActive="router-link-active">
        <span class="list-item bigger-font"><mat-icon>computer</mat-icon>Macchine</span>
      </mat-list-item>
    }
  </mat-nav-list>
</lib-frame-menu>