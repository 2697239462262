import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { updateUserPreference } from '../actions/user.actions';
import { UserService } from '../services/user/user.service';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}

  updateUserPreference$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserPreference),
        tap(async (action) => {
          const user = JSON.parse(localStorage.getItem('user') || '{}');
          user.preference = action.preference;
          localStorage.setItem('user', JSON.stringify(user));

          await this.userService.preference(user.preference);
        })
      ),
    { dispatch: false }
  );
}