import { Pipe, PipeTransform } from '@angular/core';
import { RoleFunctionModel } from '../../role/model/role-function.model';
import { RoleModel } from '../../role/model/role.model';

@Pipe({
  standalone: true,
  name: 'roleFunctions'
})
export class RoleFunctionsPipe implements PipeTransform {

  transform(roles: RoleModel[]): RoleFunctionModel[] {
    const roleFunctions: RoleFunctionModel[] = [];
    roles.forEach(role => {
      role.functions.forEach((roleFunction) => {
        roleFunctions.push(roleFunction);
      });
    });
    return roleFunctions;
  }

}
