<div #injectedFullTable><ng-content></ng-content></div> 
<span *ngIf="!injectedFullTable.children.length">
    <lib-full-table 
        [columnList]="columnList" 
        [pageSize]="10" 
        [path]="entityName" 
        [enableExport]="true" 
        [actions]="tableActions"
        [search]="search"
        [join]="join"
        (columnWidthChange)="columnWidthChange($event)"
        (columnsOrderChange)="columnsOrderChange($event)"
        [enableSettingsMenu]="true"
        *ngIf="columnList && entityName"
    ></lib-full-table>
</span>