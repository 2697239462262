import { Pipe, PipeTransform } from '@angular/core';
import { SelectablePermission } from '../../components/function-edit-dialog/function-edit-dialog.component';

@Pipe({
  standalone: true,
  name: 'getSelectedPermissions'
})
export class GetSelectedPermissionsPipe implements PipeTransform {

  transform(selectablePermissions: SelectablePermission[]): SelectablePermission[] {
    return selectablePermissions.filter(el => el.selected) || [];
  }

}
