<div class="full-width" [formGroup]="formGroup" *ngIf="showForm()">

    <ng-container [ngSwitch]="baseForm.controlType">
        <mat-label>
            {{baseForm.label}}
            <span *ngIf="baseForm.help">
                <mat-icon [matTooltip]="baseForm.help || ''" matTooltipPosition="above">help</mat-icon>
              </span>
        </mat-label>

        <ng-container *ngIf="formGroup?.get(baseForm.key)?.touched">
          <mat-error *ngIf="formGroup.get(baseForm.key)?.errors?.['customError']">
              {{formGroup.get(baseForm.key)?.errors?.['message']}}
          </mat-error>
          <mat-error *ngIf="formGroup.get(baseForm.key)?.errors?.['required']">
            Campo {{baseForm.label}} obbligatorio
          </mat-error>
        </ng-container>

        <mat-error *ngIf="baseForm.type === 'password' && formGroup?.get(baseForm.key)?.hasError('pattern')">
            8-30 caratteri, 1 maiuscolo, 1 minuscolo, 1 numero o 1 carattere speciale
        </mat-error>

        <mat-form-field class="full-width base-component" *ngSwitchDefault [ngSwitch]="baseForm.controlType" appearance="outline">

            <!--TEXT-->
            <input *ngSwitchCase="ControlTypesEnum.text" matInput [formControlName]="baseForm.key" [attr.data-cy]="baseForm.key"
                [id]="baseForm.key" [type]="baseForm.type">

            <!--PASSWORD-->
            <input *ngSwitchCase="ControlTypesEnum.password" matInput [formControlName]="baseForm.key" [attr.data-cy]="baseForm.key"
                [id]="baseForm.key" [type]="baseForm.type">

            <!--TEXTAREA-->
            <textarea matInput *ngSwitchCase="ControlTypesEnum.textArea" [formControlName]="baseForm.key" [attr.data-cy]="baseForm.key"
                [id]="baseForm.key" [type]="baseForm.type"></textarea>

            <!--SELECT-->
            <mat-select *ngSwitchCase="ControlTypesEnum.select" [id]="baseForm.key" [formControlName]="baseForm.key"
                [attr.data-cy]="baseForm.key" (openedChange)="onOpenedChange($event)">
                <lib-select-autocomplete [formControl]="filterCtrl" (searchChange)="onSearchChange($event)"></lib-select-autocomplete>
              <mat-option *ngIf="!baseForm.required" [value]="null">
                Vuoto
              </mat-option>
                <mat-option *ngFor="let opt of baseForm.options | filter: filterCtrl.value" [value]="opt.value"
                    [attr.data-cy]="opt.value">
                    {{opt.key}}
                </mat-option>
            </mat-select>

            <!--MULTIPLE-SELECT-->
            <mat-select *ngSwitchCase="ControlTypesEnum.multipleSelect" multiple [id]="baseForm.key" [formControlName]="baseForm.key"
                [attr.data-cy]="baseForm.key">
                <lib-select-autocomplete [formControl]="filterCtrl"></lib-select-autocomplete>
                <mat-option *ngFor="let opt of baseForm.options | filter: filterCtrl.value" [value]="opt.value"
                    [attr.data-cy]="opt.value">
                    {{opt.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- CHIPS -->
        <lib-input-with-chips *ngSwitchCase="ControlTypesEnum.inputChips"
            [readonly]="baseForm.readonly"
            [key]="baseForm.key"
            [formGroup]="formGroup">
        </lib-input-with-chips>

        <!--DATE-->
        <mat-form-field *ngSwitchCase="ControlTypesEnum.date" appearance="outline" class="full-width base-component" (keyup)="validateEmptyDate()">
            <input matInput [matDatepicker]="picker" [formControlName]="baseForm.key" [attr.data-cy]="baseForm.key"
                [id]="baseForm.key">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!--DATETIME-->
        <mat-form-field appearance="outline" *ngSwitchCase="ControlTypesEnum.datetime" class="full-width base-component">
            <input matInput type="datetime-local" [formControlName]="baseForm.key" required>
        </mat-form-field>

        <!--CHECKBOX-->
        <div class="full-width base-component" *ngSwitchCase="ControlTypesEnum.checkbox">
            <mat-error *ngIf="formGroup?.get(baseForm.key)?.touched && !formGroup?.get(baseForm.key)?.valid">Campo
                {{baseForm.label}}
                obbligatorio
            </mat-error>
            <mat-checkbox [id]="baseForm.key" [attr.data-cy]="baseForm.key"
                [formControlName]="baseForm.key" color="primary">{{formGroup.controls[baseForm.key]?.value ? 'Sì' : 'No'}}</mat-checkbox>
        </div>

        <!--RADIO-->
        <div class="full-width base-component" *ngSwitchCase="ControlTypesEnum.radio">
            <label [id]="baseForm.label">{{baseForm.label}}</label>
            <mat-radio-group [formControlName]="baseForm.key" [attr.data-cy]="baseForm.key">
                <mat-radio-button *ngFor="let opt of baseForm.options" [value]="opt.value" [attr.data-cy]="opt.value">
                    {{opt.key}}</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="formGroup?.get(baseForm.key)?.touched && !formGroup?.get(baseForm.key)?.valid">Campo
                {{baseForm.label}}
                obbligatorio
            </mat-error>
        </div>
    </ng-container>
</div>
