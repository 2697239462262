import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BaseForm } from '@overflo-srl/dynamic-form';
import { DynamicFormComponent } from '@overflo-srl/dynamic-form';
import { UserFormResultModel } from '../../models/user-form-result.model';
import { UserModel } from '../../models/user.model';
import { UserService } from '../../services/user/user.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { RoleModel } from '../../../role/model/role.model';
import { RoleService } from '../../../role/service/role.service';
import { HasPermissionPipe } from '../../../shared/pipes/has-permission/has-permission.pipe';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { FramePermissions } from '../../../shared/enums/frame.permissions';
import { RoleFunctionsPipe } from '../../pipes/role-functions.pipe';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    DynamicFormComponent,
    RoleFunctionsPipe,
    MatButtonModule,
  ],
  providers: [
    RoleFunctionsPipe,
    HasPermissionPipe,
  ],
  selector: 'app-user-view-dialog',
  templateUrl: './user-view-dialog.component.html',
  styleUrls: ['./user-view-dialog.component.scss']
})
export class UserViewDialogComponent implements OnInit {

  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  forms: BaseForm[] = [];
  roles?: RoleModel[];
  selectedRoles?: RoleModel[];
  submitting: boolean = false;
  user?: UserModel;

  constructor(
    public dialogRef: MatDialogRef<UserViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user?: UserModel },
    private roleService: RoleService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private hasPermissionPipe: HasPermissionPipe,
    private authService: AuthService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getMe();
    this.roles = await this.getRoles();

    let rolesWithoutSuperadmin = this.roles.filter((role) => {
      let isSuperadminRole = false;
      role.functions.forEach((roleFunction) => {
        roleFunction.permissions.forEach((rolePermission) => {
          if (rolePermission.name === FramePermissions.SUPERADMIN) {
            isSuperadminRole = true;
          }
        });
      });
      return !isSuperadminRole;
    });
    const isUserSuperadmin = await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.SUPERADMIN], true));
    const userRoles = this.data.user?.roles?.map((userRole) => this.roles?.find((role) => role.id === userRole.id)) as RoleModel[];
    const isEditedUserSuperadmin = await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.SUPERADMIN], true, userRoles));

    if (!this.data.user) {
      const forms = [
        new BaseForm({
          key: 'username',
          value: null,
          label: 'Email',
          controlType: 'text',
          required: true,
          readonly: false,
        }),
        new BaseForm({
          key: 'roles',
          value: [],
          options: (isUserSuperadmin ? this.roles : rolesWithoutSuperadmin).map((role) => {
            return {
              key: role.name,
              mnemonicId: role.name,
              description: '',
              value: role
            };
          }),
          label: 'Ruoli',
          controlType: 'multipleselect',
          required: true,
          readonly: false,
        }),
        new BaseForm({
          key: 'password',
          value: null,
          label: 'Password',
          controlType: 'text',
          type: 'password',
          required: true,
          readonly: false,
        }),
        new BaseForm({
          key: 'repeatPassword',
          value: null,
          label: 'Conferma password',
          controlType: 'text',
          type: 'password',
          required: true,
          readonly: false,
        }),
        new BaseForm({
          key: 'enabled',
          value: false,
          label: 'Abilitato',
          controlType: 'checkbox',
          required: false,
          readonly: false,
        }),
      ];
  
      this.forms = this.forms.concat(forms);
    } else {
      const canEditUser = isEditedUserSuperadmin && isUserSuperadmin || !isEditedUserSuperadmin;

      const forms = [
        new BaseForm({
          key: 'username',
          value: this.data.user.username,
          label: 'Email',
          controlType: 'text',
          required: true,
          readonly: true,
        }),
        new BaseForm({
          key: 'id',
          value: this.data.user.id,
          label: 'ID',
          controlType: 'text',
          required: true,
          readonly: true,
        }),
        new BaseForm({
          key: 'created',
          value: this.data.user.created,
          label: 'Data creazione',
          controlType: 'datetime',
          required: false,
          readonly: true,
        }),
        new BaseForm({
          key: 'updated',
          value: this.data.user.updated,
          label: 'Ultimo aggiornamento',
          controlType: 'datetime',
          required: false,
          readonly: true,
        }),
        new BaseForm({
          key: 'enabledAt',
          value: this.data.user.enabledAt,
          label: 'Data abilitazione',
          controlType: 'datetime',
          required: false,
          readonly: true,
        }),
        new BaseForm({
          key: 'lastLogin',
          value: this.data.user.lastLogin,
          label: 'Ultimo login',
          controlType: 'datetime',
          required: false,
          readonly: true,
        }),
        new BaseForm({
          key: 'roles',
          value: this.data.user.roles?.map((role) => this.roles?.find((_role) => _role.id === role.id)),
          options: (isUserSuperadmin || (!isUserSuperadmin && isEditedUserSuperadmin) ? this.roles : rolesWithoutSuperadmin).map((role) => {
            return {
              key: role.name,
              mnemonicId: role.name,
              description: '',
              value: role
            };
          }),
          label: 'Ruoli',
          controlType: 'multipleselect',
          required: true,
          readonly: !canEditUser,
        }),
        new BaseForm({
          key: 'enabled',
          value: this.data.user.enabled,
          label: 'Abilitato',
          controlType: 'checkbox',
          required: false,
          readonly: !canEditUser,
        }),
      ];
  
      this.forms = this.forms.concat(forms);
    }
  }

  async getRoles(): Promise<RoleModel[]> {
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: "functions"}, {field: "functions.permissions"}]);
    const roles = await this.roleService.getMany(qb.query());
    return roles || [];
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  save(formResult: UserFormResultModel) {
    this.selectedRoles = formResult.roles;

    if (!this.submitting) {
      return;
    }
    if (!this.data.user) {
      this.create(formResult);
    } else {
      this.update(formResult);
    }
  }

  async create(formResult: UserFormResultModel) {
    const enabled = formResult.enabled;
    const roles = formResult.roles;
    const username = formResult.username;
    const password = formResult.password;
    const repeatPassword = formResult.repeatPassword;

    if (password !== repeatPassword) {
      this.snackBarService.openErrorSnackBar(`Le password inserite non corrispondono`);
      return;
    }

    const email = formResult.username;
    const createData: Partial<UserModel> = {
      username: username,
      email: email,
      password: password,
      updated: new Date(),
      created: new Date(),
      createdBy: this.user?.id,
      enabled: enabled, 
      roles: roles,
    };
    if (enabled) {
      createData.enabledAt = new Date();
    }
    const createdUser = await this.userService.createOne(createData);
    this.snackBarService.openInfoSnackBar(`Utente creato con successo`);
    this.dialogRef.close(true);
  }

  async update(formResult: UserFormResultModel) {
    const userId = this.data.user?.id as number;
    const enabled = formResult.enabled;
    const roles = formResult.roles;
    const updateData: Partial<UserModel> = {
      updated: new Date(),
      updatedBy: this.user?.id,
      enabled: enabled, 
      roles: roles,
      version: this.data.user?.version,
    };
    if (enabled && !this.data.user?.enabled) {
      updateData.enabledAt = new Date();
    }
    const updatedUser = await this.userService.updateOne(userId, updateData);
    this.snackBarService.openInfoSnackBar(`Utente aggiornato con successo`);
    this.dialogRef.close(true);
  }

}
