import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { loginSuccess, login, loginError, logout, logoutSuccess, storeTokens } from '../actions/auth.actions';
import { Router } from '@angular/router';
import { LoginResponseDto, TokenModel } from '../../shared/models/login.model';
import { AuthService } from '../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { impersonate } from "../../user/actions/user.actions";
import { AppState, clearAuthStorageData } from '../../shared/reducers';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions, 
    private router: Router, 
    private authService: AuthService, 
    private store: Store<AppState>,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(action =>
        this.authService.login(action.username, action.password).pipe(
          map((response: LoginResponseDto) => {
            return loginSuccess(
              this.authService.parseLoginResponse(response)
            )
          }),
          catchError(error => {
            return of(loginError({ error: error?.error?.message ? error?.error?.message : 'Errore del server' }));
          })
        )
      )
    )
  );

  loggedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap((response: LoginResponseDto) => {
          this.store.dispatch(storeTokens(response));
          return this.router.navigate(['dashboard']);
        })
      ),
    { dispatch: false }
  );

  
  impersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(impersonate),
      exhaustMap((action) =>
        this.authService.impersonate(action.id).pipe(
          map((response) => loginSuccess(this.authService.parseLoginResponse(response))),
          catchError((error) => of(loginError({ error: error?.error?.message ? error?.error?.message : 'Errore del server' })))
        )
      )
    )
  );

  storeTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeTokens),
        tap(action => {
          localStorage.setItem('auth', JSON.stringify(action.token));
          localStorage.setItem('refresh', JSON.stringify(action.refreshToken));
          localStorage.setItem('user', JSON.stringify(action.user));
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      exhaustMap(() => this.authService.logout().pipe(map(() => logoutSuccess())))
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess),
        tap(async () => {
          clearAuthStorageData();
          // await this.router.navigate(['login']);
          // location.reload();
        })
      ),
    { dispatch: false }
  );
}
