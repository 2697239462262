import { NgModule } from '@angular/core';
import { UserListComponent } from './components/user-list/user-list.component';
import { RouterModule, Routes } from "@angular/router";
import { EnabledUserGuard } from '../shared/guards/enabled-user/enabled-user.guard';
import { RolesGuard } from '../shared/guards/roles/roles.guard';
import { FramePermissions } from '../shared/enums/frame.permissions';

const guards: any[] = [EnabledUserGuard];

const routes: Routes = [
  {
    path: '',
    component: UserListComponent,
    canActivate: [...guards, RolesGuard],
    data: { roles: [FramePermissions.user] },
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class UserRoutingModule { }
