import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'isBooleanType'
})
export class IsBooleanTypePipe implements PipeTransform {

  transform(value: any): boolean {
    return typeof value === 'boolean';
  }

}
