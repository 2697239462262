export enum QueryParamsEnum{
  SORT_ACTIVE = 'sort_active',
  SORT_DIRECTION = 'sort_direction',
  PAGE_INDEX = 'page_index',
  PAGE_SIZE = 'page_size',
  QUICK_SEARCH = 'quick_search',
  OPERATION_PARAM = '__operation',
  VALUE_PARAM = '__value',
  START_PARAM = '__start',
  END_PARAM = '__end',
}
