import { Pipe, PipeTransform } from '@angular/core';
import {ColumnModel} from "../model/column.model";
import {FormGroup} from "@angular/forms";
import {FormColumn, GetColumnFilterOptionsPipe} from './get-column-filter-options.pipe';
import {FullTableFilterComponent} from '../full-table-filter/full-table-filter.component';

@Pipe({
  name: 'getFilterOptionDisplayedValue',
  standalone: true,
})
export class GetFilterOptionDisplayedValuePipe implements PipeTransform {

  constructor(private getColumnFilterOptionsPipe: GetColumnFilterOptionsPipe) {
  }
  transform(colName: string, value: string, columnList: ColumnModel[], filterForm: FormColumn) {
    const filterOptions = this.getColumnFilterOptionsPipe.transform(columnList, filterForm, colName);
    return filterOptions ? filterOptions.find((opt) => opt.realValue === value)?.displayedValue : null;
  }
}
