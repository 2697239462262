import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { TokenModel } from '../../shared/models/login.model';
import { UserModel } from '../../shared/models/user.model';
import { getItemFromLS } from '../../shared/utils/frame-utils';
import { LocalStorageVariablesEnum } from '../../shared/enums/local-storage-variables.enum';

export const authFeatureKey = 'auth';

export interface State {
  token: TokenModel | undefined;
  user: UserModel | undefined;
  refreshToken: string | '';
  error: string | '';
}

export const initialState: State = {
  token: getItemFromLS(LocalStorageVariablesEnum.auth),
  user: getItemFromLS(LocalStorageVariablesEnum.user),
  refreshToken: getItemFromLS(LocalStorageVariablesEnum.refresh),
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.login, (state, action) => ({ ...state, token: undefined, user: undefined, refreshToken: '', error: '' })),
  on(AuthActions.loginSuccess, (state, action) => ({ ...state, token: action.token, refreshToken: action.refreshToken as string, user: action.user, error: '' })),
  on(AuthActions.logoutSuccess, () => initialState),
  on(AuthActions.loginError, (state, action) => ({ ...state, token: undefined, user: undefined, refreshToken: '', error: action.error })),
  on(AuthActions.storeTokens, (state, action) => ({ ...state, token: action.token, refreshToken: action.refreshToken as string, user: action.user, error: '' })),
);
