import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { COMMA, SPACE, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { NgFor } from "@angular/common";

@Component({
  standalone: true,
  imports: [
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatChipsModule,
  ],
  selector: "lib-input-with-chips",
  templateUrl: "./input-with-chips.component.html",
  styleUrls: ["./input-with-chips.component.scss"],
})
export class InputWithChipsComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() key: string;
  @Input() formGroup: UntypedFormGroup;

  defaultSeparatorKeyCodes = [COMMA, SPACE, ENTER];

  chips = new Array<string>();

  private inputWithChipsFormControl: AbstractControl<any, any>;
  inputFormControl = new FormControl('');

  ngOnInit(): void {
    this.inputWithChipsFormControl = this.formGroup.get(this.key)!;
    this.chips = this.inputWithChipsFormControl.getRawValue();
  }

  addChip(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.chips.push(event.value);
    }
    this.sync();
    event.chipInput!.clear();
  }

  removeChip(chip: string) {
    const idx = this.chips.indexOf(chip);
    if (idx >= 0) {
      this.chips.splice(idx, 1);
    }
    this.sync();
  }

  private sync() {
    this.inputWithChipsFormControl.setValue(this.chips);
  }
}
