import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageVariablesEnum } from '../../enums/local-storage-variables.enum';
import { getItemFromLS } from '../../utils/frame-utils';

@Injectable({
  providedIn: 'root'
})
export class EnabledUserGuard implements CanActivate {
  
  constructor(
    private router: Router,
  ) {}

  canActivate(
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = getItemFromLS(LocalStorageVariablesEnum.user);
    const userAllowed = user?.enabled ? true : false;
    if (!userAllowed) {
      this.router.navigate(['disabled-user']);
    }
    return userAllowed;
  }
  
}
