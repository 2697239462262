import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RolesGuard } from './guards/roles/roles.guard';
import { ApplicationInterceptor } from './services/interceptor/Interceptor';

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule,
  ],
  exports: [
    RouterModule, 
  ],
  providers: [
    ApplicationInterceptor,
    RolesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true },
  ],
})
export class FrameSharedModule {}
