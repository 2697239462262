<div class="larger-form-fields">
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" *ngIf="formGroup">
    <div class="fields-fill">
      <div 
        class="field" 
        *ngFor="let formBase of forms; let formBaseIndex = index;"
        [ngClass]="{'field-full': formBase.fullWidth}"
      >
        <lib-base-form class="field-full" [baseForm]="formBase" [formGroup]="formGroup"></lib-base-form>
      </div>
    </div>
    <button class="stretch" mat-raised-button color="primary" type="submit" *ngIf="showSaveButton()"
      data-cy="dynamicFormSaveButton">
      {{formGroup.valid ? 'Salva' : invalidSend && partialSend ? 'Salva parziale' : 'Salva'}}
    </button>
  </form>
</div>