import { CommonModule, DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { userFeatureKey, userReducer } from '../auth/reducers/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '../user/effects/user.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature(userFeatureKey, userReducer),
  ],
  providers: [
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class FrameEntityModule {
}