import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() showManualLink: boolean = true;
  @Input() public : boolean = false;

  constructor() {}

  async ngOnInit(): Promise<void> {
  }
}
