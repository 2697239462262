import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'lib-pwa-installation-dialog',
  templateUrl: './pwa-installation-dialog.component.html',
  styleUrls: ['./pwa-installation-dialog.component.scss'],
})
export class PwaInstallationDialogComponent {
  constructor(public dialogRef: MatDialogRef<PwaInstallationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {}
}
