import { Pipe, PipeTransform } from '@angular/core';
import {ColumnModel} from "../model/column.model";

@Pipe({
  name: 'viewableColumnList',
  standalone: true,
})
export class ViewableColumnListPipe implements PipeTransform {
  transform(columnList: ColumnModel[]) {
    return columnList.filter(c => c.hidden === null || c.hidden === undefined || !c.hidden);
  }
}
