import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrameInternalRoutes, FrameExternalRoutes, CanLoadGuard } from '@overflo1/frame-frontend';
import { MachineRoutes } from './machine/machine.routes';
import { MenuComponent } from './shared/components/menu/menu.component';

const guards: any[] = [CanLoadGuard];

const routes: Routes = [
  ...FrameExternalRoutes,
  {
    path: '',
    component: MenuComponent,
    children: [
        {
          path: 'home',
          loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
          canLoad: guards
        },
        ...MachineRoutes,
        ...FrameInternalRoutes,
        { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanLoadGuard]
})
export class AppRoutingModule { }
