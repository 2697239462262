import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { DynamicFormComponent, BaseForm } from '@overflo-srl/dynamic-form';
import { UserModel } from '../../../shared/models/user.model';
import { AppState } from '../../../shared/reducers';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { logout } from '../../actions/auth.actions';
import { PasswordEditFormResultModel } from '../../models/password-edit-form-result.model';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  standalone: true,
  imports: [
    DynamicFormComponent,
    MatButtonModule,
  ],
  selector: 'app-password-edit',
  templateUrl: './password-edit.component.html',
  styleUrls: ['./password-edit.component.scss']
})
export class PasswordEditComponent implements OnInit {

  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  forms: BaseForm[] = [];
  submitting: boolean = false;
  user?: UserModel;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private snackBarService: SnackBarService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getMe();
    const forms = [
      new BaseForm({
        key: 'username',
        value: this.user?.username,
        label: 'Username',
        controlType: 'text',
        required: false,
        readonly: true,
      }),
      new BaseForm({
        key: 'oldPassword',
        value: null,
        label: 'Password attuale',
        controlType: 'text',
        type: 'password',
        required: true,
        readonly: false,
      }),
      new BaseForm({
        key: 'password',
        value: null,
        label: 'Nuova password',
        controlType: 'text',
        type: 'password',
        required: true,
        readonly: false,
      }),
      new BaseForm({
        key: 'repeatPassword',
        value: null,
        label: 'Conferma nuova password',
        controlType: 'text',
        type: 'password',
        required: true,
        readonly: false,
      }),
    ];

    this.forms = this.forms.concat(forms);
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async save(formResult: PasswordEditFormResultModel) {
    if (!this.submitting) {
      return;
    }

    const {oldPassword, password, repeatPassword} = formResult;

    if (password !== repeatPassword) {
      this.snackBarService.openErrorSnackBar(`Le password inserite non corrispondono`);
      return;
    }

    if (oldPassword === password) {
      this.snackBarService.openErrorSnackBar(`La nuova password non può essere uguale alla password attuale`);
      return;
    }

    await this.authService.editPassword(oldPassword, password, repeatPassword);
    
    this.snackBarService.openInfoSnackBar(`Password modificata con successo, stai per essere disconnesso...`);

    setTimeout(() => {
      this.store.dispatch(logout());
    }, 3000);
  }

}
