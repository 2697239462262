import { Component } from '@angular/core';
import { PwaService } from '@overflo1/frame-frontend';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'almabox';

  constructor(
    public pwaService: PwaService,
  ) {}
}
