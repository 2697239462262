import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CanLoadGuard } from '../auth/guards/auth-guard/can-load.guard';
import { FrameEntityListComponent } from './components/frame-entity-list/frame-entity-list.component';
import { FrameEntityViewComponent } from './components/frame-entity-view/frame-entity-view.component';

const guards: any[] = [CanLoadGuard];

const routes: Routes = [
  { 
    path: '',
    children: [
      {
        path: ':entityName/:entityId',
        component: FrameEntityViewComponent,
        canLoad: guards
      },
      {
        path: ':entityName',
        component: FrameEntityListComponent,
        canLoad: guards
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class FrameEntityRoutingModule { }
