import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import {ExtractDistinctPermissions} from "../../../role/role.utils";
import { getRoles } from "../../../auth/selectors/auth.selector";
import { State as AppState } from "../../../auth/reducers/auth.reducers";
import { FramePermissions } from "../../enums/frame.permissions";

@Injectable()
export class RolesGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(getRoles),
      map((roles) => {
        const requiredPermissions = route.data['roles'] as Array<string>;
        const userDistinctPermissions = ExtractDistinctPermissions(roles);

        if (requiredPermissions) {
          if (userDistinctPermissions) {
            if (userDistinctPermissions.includes(FramePermissions.SUPERADMIN) || userDistinctPermissions.includes(FramePermissions.ADMIN)) {
              // ADMIN and SUPERADMIN can go everywhere
              return true;
            }
            for (const requiredPermission of requiredPermissions) {
              if (userDistinctPermissions.includes(requiredPermission)) {
                return true;
              }
            }
            // no role match
            return this.router.parseUrl("/dashboard");
            // return false;
          } else {
            // no roles at all
            return this.router.parseUrl("/dashboard");
            // return false;
          }
        } else {
          return true;
        }
      })
    );
  }
}
