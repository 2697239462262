import { Pipe, PipeTransform } from '@angular/core';
import {ColumnModel} from "../model/column.model";

@Pipe({
  name: 'applyColumnValueFunction',
  standalone: true,
})
export class ApplyColumnValueFunctionPipe implements PipeTransform {
  transform(value: any, column: ColumnModel) {
    return column.value(value);
  }
}
