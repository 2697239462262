import { createAction, props } from "@ngrx/store";
import { UserModel } from "../models/user.model";
import { UserPreferenceModel } from "../models/user-preference.model";

export const loadUsers = createAction("[User] Load Users");
export const loadUsersSuccess = createAction(
  "[User] Load Users Success",
  props<{ data: UserModel[] }>()
);
export const loadUsersFailure = createAction("[User] Load Users Failure");
export const createUser = createAction(
  "[User] Create User",
  props<{ user: UserModel }>()
);
export const createUserSuccess = createAction(
  "[User] Create User Success",
  props<{ user: UserModel }>()
);
export const createUserFailure = createAction("[User] Create User Failure");
export const updateUser = createAction(
  "[User] Update User",
  props<{ user: UserModel }>()
);
export const updateUserSuccess = createAction(
  "[User] Update User Success",
  props<{ user: UserModel }>()
);
export const updateUserFailure = createAction("[User] Update User Failure");
export const removeUser = createAction(
  "[User] Remove User",
  props<{ id: number }>()
);
export const removeUserSuccess = createAction(
  "[User] Remove User Success",
  props<{ user: UserModel }>()
);
export const removeUserFailure = createAction("[User] Remove User Failure");

export const impersonate = createAction(
  "[User] impersonate user",
  props<{ id: number }>()
);
export const updateUserPreference = createAction(
  '[User] Update User Preference',
  props<{ preference: UserPreferenceModel }>()
);
