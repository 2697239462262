import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../shared/reducers';
import { logout } from '../../actions/auth.actions';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-disabled-user',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './disabled-user.component.html',
  styleUrls: ['./disabled-user.component.scss']
})
export class DisabledUserComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,

    private readonly store: Store<AppState>,
    private authService: AuthService,
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    const user = await this.authService.getMe();
    if (user?.enabled) {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(logout());
  }

}
