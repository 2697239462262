import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../user/models/user.model';
import { updateUserPreference } from '../../user/actions/user.actions';
import { LocalStorageVariablesEnum } from '../../shared/enums/local-storage-variables.enum';
import { getItemFromLS } from '../../shared/utils/frame-utils';

export const userFeatureKey = 'user';

export interface UserState {
  user: UserModel | null;
}

export const initialState: UserState = {
  user: getItemFromLS(LocalStorageVariablesEnum.user),
};

export const userReducer = createReducer(
  initialState,
  on(updateUserPreference, (state, { preference }) => ({
    ...state,
    user: {
      ...state.user,
      preference
    }
  }))
);