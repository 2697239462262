import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effect';
import { authFeatureKey, reducer } from './reducers/auth.reducers';
import { AuthRoutingModule } from './auth-routing.module';
import { FrameSharedModule } from '../shared/frame-shared.module';

@NgModule({
  declarations: [
  ],
  imports: [
    AuthRoutingModule,
    FrameSharedModule,
    EffectsModule.forFeature([AuthEffects]), 
    StoreModule.forFeature(authFeatureKey, reducer),
  ],
  exports: [
  ],
})
export class AuthModule { }
