import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ColumnModel } from '../model/column.model';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyValuePipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SettingOptionEnum, SettingOptionEnumTranslation } from '../enums/setting-option.enum';
import { OrderColumnListPipe } from '../pipes/order-column-list.pipe';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';


@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatSliderModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    KeyValuePipe,
    OrderColumnListPipe,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    MatIcon,
    MatIconButton,
    MatTooltip
  ],
  providers: [
    OrderColumnListPipe,
  ],
  selector: 'lib-full-table-settings',
  templateUrl: './full-table-settings.component.html',
  styleUrls: ['./full-table-settings.component.scss']
})
export class FullTableSettingsComponent implements OnInit {
  readonly SettingOptionEnum = SettingOptionEnum;
  readonly SettingOptionEnumTranslation = SettingOptionEnumTranslation;
  @Input() columnList!: ColumnModel[];
  @Output() widthChange = new EventEmitter<ColumnModel>();
  @Output() orderChange = new EventEmitter<ColumnModel[]>();

  selectedSettingOption?: string;

  constructor(
    private orderColumnListPipe: OrderColumnListPipe,
  ) {

  }

  ngOnInit(): void {
    this.columnList = this.orderColumnListPipe.transform(this.columnList);
  }

  onWidthChange(column: ColumnModel): void {
    this.widthChange.emit(column);
  }

  formatLabel(value: number): string {
    if (!value) {
      return 'Default';
    }
    return `${value}px`;
  }

  chooseSettingOption(option: string) {
    this.selectedSettingOption = option;
  }

  reset() {
    this.selectedSettingOption = undefined;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);

    this.columnList = this.columnList.map((col, index) => ({
      ...col,
      order: index
    }));

    this.orderChange.emit(this.columnList);
  }
}
