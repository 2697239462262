import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { BaseForm } from "../../models/base-form.model";
import {ControlTypesEnum} from "../../enums/control-types.enum";
import {SelectOptionModel} from "../../models/select-option.model";
import {debounceTime, Subject} from "rxjs";
import {CustomErrorModel} from "../../models/custom-error.model";
import { InputWithChipsComponent } from "../input-with-chips/input-with-chips.component";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SelectAutocompleteComponent } from "../select-autocomplete/select-autocomplete.component";
import { FilterPipe } from "../../pipes/filter/filter.pipe";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    InputWithChipsComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    SelectAutocompleteComponent,
    FilterPipe,
  ],
  providers: [
    FilterPipe,
  ],
  selector: 'lib-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit {
  @Input() baseForm: BaseForm;
  @Input() formGroup: UntypedFormGroup;

  searchDebounce: Subject<string> = new Subject();
  filterCtrl = new UntypedFormControl('');
  protected readonly ControlTypesEnum = ControlTypesEnum;
  defaultOptions: SelectOptionModel[];


  get getControl() {
    return this.formGroup?.get(this.baseForm.key);
  }

  ngOnInit() {
    this.updateRequiredValidators();
    if (this.checkIfShouldSubscribe()){
      this.subscribeToInputOnValueChange();
    }
    if (this.baseForm.optionsFunc) {
      this.baseForm.options = this.baseForm.optionsFunc(this.formGroup.getRawValue());
      this.formGroup.valueChanges.subscribe(form => {
        this.baseForm.options = this.baseForm.optionsFunc?.(form) as SelectOptionModel[];
      });
    }
    if (this.baseForm.searchWithDebounceFunc){
      this.setDefaultOptionsAndSearchDebounce();
    }
    if (this.baseForm.validators){
      this.getControl?.addValidators(this.baseForm.validators);
    }
  }

  private findOptionInArrayByValue(value: string, array: SelectOptionModel[]): SelectOptionModel | undefined{
    return array.find((opt) => {
      return opt.value === value;
    });
  }

  showForm() {
    if (this.baseForm.if) {
      return this.baseForm.if(this.formGroup.getRawValue());
    }
    return true;
  }

  updateRequiredValidators() {
    if (this.baseForm.if && this.baseForm.required) {
      this.formGroup.valueChanges.subscribe(value => {
        if (this.baseForm.if(this.formGroup.getRawValue())) {
          if (!this.getControl?.hasValidator(Validators.required)) {
            this.getControl?.markAsUntouched();
            this.getControl?.addValidators(Validators.required);
            this.getControl?.updateValueAndValidity({onlySelf: true});
          }
        } else {
          if (this.getControl?.hasValidator(Validators.required)) {
            this.getControl?.removeValidators(Validators.required);
            this.getControl?.reset();
            this.getControl?.markAsUntouched()
            this.getControl?.updateValueAndValidity({onlySelf: true});
          }
        }
      });
    }
  }

  compareObjects(object1: any, object2: any) {
    return object1 === object2;
  }

  private subscribeToInputOnValueChange(){
    const control = this.formGroup.controls[this.baseForm.key];
    control.valueChanges.subscribe((value) => {
      const newValue = this.baseForm.modifyValueOnValueChange ? this.baseForm.modifyValueOnValueChange(value) : value;
      if (value != newValue){
        control.setValue(newValue);
      }
    });
  }

  private checkIfShouldSubscribe(){
    const allowedControlTypes: string[] = [ControlTypesEnum.text, ControlTypesEnum.textArea, ControlTypesEnum.password];
    return this.baseForm.modifyValueOnValueChange && allowedControlTypes.includes(this.baseForm.controlType)
  }

  onSearchChange(value: string){
    this.searchDebounce.next(value);
  }

  onOpenedChange(isOpen: boolean){
    if (this.baseForm.searchWithDebounceFunc && !isOpen){
      const options: SelectOptionModel[] = this.defaultOptions;
      this.setBaseFormOptions(options);
    }
  }

  private async setSearchWithDebounce(searchValue: string){
    const options: SelectOptionModel[] = this.baseForm.searchWithDebounceFunc ? await this.baseForm.searchWithDebounceFunc(searchValue) : this.baseForm.options;
    this.setBaseFormOptions(options);
  }

  private setBaseFormOptions(options: SelectOptionModel[]){
    const currentValue = this.getCurrentValue();
    if(!(this.findOptionInArrayByValue(currentValue, options))){
      const selectedOption = this.findOptionInArrayByValue(currentValue, this.baseForm.options);
      if (selectedOption){
        options.push(selectedOption);
      }
    }
    this.baseForm.options = options;
  }

  private setDefaultOptionsAndSearchDebounce(){
    this.defaultOptions = this.baseForm.options;
    this.searchDebounce.pipe(debounceTime(this.baseForm.debounceTime)).subscribe(async (searchValue: string) => {
      await this.setSearchWithDebounce(searchValue);
    });
  }

  private getCurrentValue(){
    return this.formGroup.controls[this.baseForm.key].value;
  }

  protected validateEmptyDate() {
    const control = this.getControl;
    control?.markAsTouched();

    if (!this.baseForm.required && control){
      if (this.isDateControlEmpty(control)){
        control?.reset();
      } else if (control.value == null) {
        control.setErrors(this.getDateCustomError());
      } else if (control?.errors && control.errors?.['matDatepickerParse'] && Object.keys(control.errors).length === 1){
        control.setErrors(null);
      }
    }
  }

  private isDateControlEmpty(control: AbstractControl) {
    return control?.errors?.['matDatepickerParse']?.['text'] === '';
  }

  private getDateCustomError(): CustomErrorModel {
    return {
      customError: true,
      message: `Se viene inserita una data deve essere valida`
    }
  }
}

