import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { startWith } from "rxjs/operators";
import { DatePipe, NgClass, NgFor, NgIf } from "@angular/common";
import { BaseForm } from "../../models/base-form.model";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import {ControlTypesEnum} from "../../enums/control-types.enum";
import { BaseFormComponent } from "../base-form/base-form.component";
import { MatButtonModule } from "@angular/material/button";

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    BaseFormComponent,
    MatButtonModule,
  ],
  selector: 'lib-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  
})
export class DynamicFormComponent implements OnChanges {

  @Input() forms: BaseForm[] | null = [];
  @Output() send = new EventEmitter();
  @Input() showButtonAndEmit?: boolean = true;
  @Input() justHideButton?: boolean = false;
  @Input() partialSend?: boolean = true;
  @Input() showConfirmationDialog?: boolean = true;
  @Input() fullWidth?: boolean = false;

  formGroup!: UntypedFormGroup;
  payLoad = '';

  invalidSend = false;

  constructor(
    private el: ElementRef,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
  ) {
  }

  ngOnChanges() {
    if (this.forms?.length) {
      this.formGroup = this.toFormGroup(this.forms as BaseForm[]);
    }
    if (!this.showButtonAndEmit && this.formGroup) {
      this.formGroup.valueChanges.pipe(startWith(null)).subscribe(formValue => {
        if (this.formGroup.valid) {
          this.send.emit(this.formGroup.getRawValue());
        } else {
          this.send.emit({...this.formGroup.getRawValue(), isInvalidForm: true});
        }
      })
    }
  }

  async onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup?.valid) {
      if (this.showConfirmationDialog) {
        const title = `Conferma salvataggio`;
        const description = `Sei sicuro di voler salvare questo form? L' operazione è irreversibile.`;
        const confirmation = await this.matDialog.open(ConfirmationDialogComponent, {
          data: {
            title,
            description
          }
        }).afterClosed().toPromise();
        if (confirmation) {
          this.send.emit(this.formGroup.getRawValue());
        }
      } else {
        this.send.emit(this.formGroup.getRawValue());
      }

    } else if (this.invalidSend) {
      if (this.partialSend) {
        this.send.emit({...this.formGroup.getRawValue(), isInvalidForm: true});
      }
    } else {
      // this.invalidSend = true;
      const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

      if (invalidControl) {
        invalidControl.focus();
      }
    }
  }

  toFormGroup(questions: BaseForm[]) {
    const group: any = {};
    questions
      .sort((a, b) => a.order - b.order)
      .forEach(question => {
        if (question.controlType === ControlTypesEnum.datetime) {
          question.value = this.datePipe.transform(question.value, 'yyyy-MM-ddTHH:mm');
        }
        const validators = [];
        if (question.required) {
          validators.push(Validators.required);
        }
        if (question.type === ControlTypesEnum.password) {
          validators.push(Validators.pattern(question.passwordPattern));
        }
        group[question.key] = new UntypedFormControl({value: question.value, disabled: question.readonly}, validators);
      });
    return new UntypedFormGroup(group);
  }

  showSaveButton() {
    if(this.justHideButton) {
      return false;
    }

    if (this.showButtonAndEmit) {
      return !this.formGroup?.disabled
    } else {
      return false;
    }
  }

  enableForm() {
    this.formGroup.enable();
  }

  validateForm() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup?.valid) {
      return (this.formGroup.getRawValue());
    } else if (this.invalidSend) {
      if (this.partialSend) {
        return ({...this.formGroup.getRawValue(), isInvalidForm: true});
      }
    } else {
      this.invalidSend = true;
      const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

      if (invalidControl) {
        invalidControl.focus();
      }
    }
  }
}
