<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content class="mat-body" data-cy="confirmationDialog">
  {{ data.description }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>No</button>
  <button mat-flat-button [color]="data.color || MaterialColorEnum.primary" [mat-dialog-close]="true">Sì</button>
</mat-dialog-actions>
