// Elenco dei NUOVI permessi
export enum AlmaboxPermissions {
    SUPERADMIN = "superadmin",
    ADMIN = "admin",

    impersonate = "impersonate",

    roleRead = "role.read",
    roleCreate = "role.create",
    roleEdit = "role.update",
    roleDelete = "role.delete",

    roleFunctionRead = "role-function.read",
    roleFunctionCreate = "role-function.create",
    roleFunctionEdit = "role-function.update",
    roleFunctionDelete = "role-function.delete",

    rolePermissionRead = "role-permission.read",

    user = "user",
    userRead = "user.read",
    userCreate = "user.create",
    userEdit = "user.update",
    userDelete = "user.delete",

    machine = "machine",
    machineRead = "machine.read",
    machineCreate = "machine.create",
    machineEdit = "machine.update",
    machineDelete = "machine.delete",

    request = "request",
    requestRead = "request.read",
    requestCreate = "request.create",
    requestEdit = "request.update",
    requestDelete = "request.delete",
}
