import { HttpClient, HttpHeaders } from "@angular/common/http";
import {lastValueFrom} from "rxjs";

export interface HttpCallerOptions{
  responseType?: any,
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  } | undefined;
  observe?: any
}

export abstract class HttpCallerService {

  protected constructor(
    protected readonly http: HttpClient,
    protected readonly basePath: string,
  ) {
  }

  protected async get<T>(relativeUrl: string, options?: HttpCallerOptions){
    const data$ = this.http.get<T>(this.basePath + relativeUrl, options);
    return await lastValueFrom(data$);
  }

  protected async post<T>(relativeUrl: string, body: any, options?: HttpCallerOptions){
    const data$ = this.http.post<T>(this.basePath + relativeUrl, body, options);
    return await lastValueFrom(data$);
  }

  protected async patch<T>(relativeUrl: string, body: any, options?: HttpCallerOptions){
    const data$ = this.http.patch<T>(this.basePath + relativeUrl, body, options);
    return await lastValueFrom(data$);
  }

  protected async delete<T>(relativeUrl: string, options?: HttpCallerOptions){
    const data$ = this.http.delete<T>(this.basePath + relativeUrl, options);
    return await lastValueFrom(data$);
  }
}
