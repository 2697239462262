import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { RolePermissionModel } from "../model/role-permission.model";
import { HttpCallerService } from '../../shared/services/http-caller/http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) {
    super(http, backendBasePath);
  }

  async getOne(rolePermissionId: number, query?: string) {
    return await this.get<RolePermissionModel>(`/role-permission/${rolePermissionId}?${query ?? ''}`);
  }

  async getMany(query?: string) {
    return await this.get<RolePermissionModel[]>(`/role-permission?${query ?? ''}`);
  }
}
