import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ColumnModel, FullTableComponent } from '@overflo-srl/full-table';
import { CommonFrameEntityFieldsEnum } from '../../../shared/enums/common-frame-entity-fields.enum';
import { FrameEntityType } from '../../../shared/enums/frame-entity-type.enum';
import { FullTableColumnType } from '../../../shared/enums/full-table-column-type.enum';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { frameEntityMapping } from '../../../shared/utils/frame-utils';
import { FrameEntityMetadataModel } from '../../models/frame-entity-metadata.model';
import { FullTableConfig } from '../../models/full-table-config.model';
import { FullTableEntityColumns } from '../../models/full-table-entity-columns.model';
import { FrameEntityHttpService } from '../../services/frame-entity-http/frame-entity-http.service';
import { SCondition, QueryJoin, QueryJoinArr } from "@nestjsx/crud-request";
import { ActivatedRoute } from '@angular/router';
import { updateUserPreference } from '../../../user/actions/user.actions';
import { Store, select } from '@ngrx/store';
import { selectUserPreference } from '../../../user/selectors/user.selectors';
import { UserPreferenceModel } from '../../../user/models/user-preference.model';
import { getMe } from '../../../auth/selectors/auth.selector';
import { UserModel } from '../../../shared/models/user.model';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { HasPermissionPipe } from '../../../shared/pipes/has-permission/has-permission.pipe';
import { firstValueFrom } from 'rxjs';
import { BasePermissions } from '../../../base-permissions';

@Component({
  selector: 'lib-frame-entity-table',
  standalone: true,
  imports: [
    NgIf,
    FullTableComponent,
    DatePipe,
  ],
  templateUrl: './frame-entity-table.component.html',
  styleUrl: './frame-entity-table.component.scss'
})
export class FrameEntityTableComponent {

  @Input() entityName?: string;
  @Input() entityConfig?: FullTableEntityColumns;
  @Input() search!: SCondition;
  @Input() join!: QueryJoin | QueryJoinArr | (QueryJoin | QueryJoinArr)[];
  @Output() goToView = new EventEmitter<{entityName?: string; entityId?: number}>();
  @Output() openDeleteConfirmationDialog = new EventEmitter<any>();

  @Input() columnList?: ColumnModel[];
  tableActions = new EventEmitter<void | {
    type: string;
    element: any;
  }>();
  @Input() actions?: {icon: string, type: string}[];
  @Output() actionEmitter = new EventEmitter<{type: string; element: any}>();
  @Input() actionsEnabled: boolean = true;

  userPreference?: UserPreferenceModel;

  constructor(
    @Inject('fullTableConfig') protected fullTableConfig: FullTableConfig,
    @Inject('dateFormat') protected dateFormat: string,
    @Inject('locale') protected locale: string,
    private frameEntityHttpService: FrameEntityHttpService<any>,
    private datePipe: DatePipe,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private store: Store,
    private authService: AuthService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {
    this.store
      .pipe(select(selectUserPreference))
      .subscribe(async (userPreference?: UserPreferenceModel) => {
        this.userPreference = userPreference;

        if (!this.userPreference) {
          const user = await this.authService.getMe();
          this.userPreference = user?.preference;
        }

        this.applyUserPreference();
      });
  }

  async ngOnInit() {
    if (!this.entityName) {
      return;
    }

    this.route.queryParams.subscribe(params => {
      const filtersParam = params[`${this.entityName}Filters`];
      if (filtersParam) {
        const filters = JSON.parse(filtersParam);
        const search: any = {};
        Object.keys(filters).forEach((key) => {
          search[key] = { $eq: filters[key] };
        });
        this.search = search;
      }
    });

    if (!this.entityConfig) {
      this.entityConfig = this.fullTableConfig?.entityColumns?.[this.entityName];
    }

    const joinConfig = this.entityConfig?.join;
    if (!this.join && joinConfig) {
      this.join = joinConfig;
    }

    this.prepareFullTable();
  }

  async prepareFullTable() {
    if (!this.entityName) {
      return;
    }
    const metadata = await this.frameEntityHttpService.getMetadata(this.entityName);
    const generatedColumns = this.getGeneratedColumns(metadata);
    let configuredColumns = this.applyColumnsConfig(generatedColumns);

    const allowedColumns = this.entityConfig?.allowedColumns;
    if (allowedColumns?.length) {
      configuredColumns = configuredColumns.filter((column) => allowedColumns.includes(column.def));
    } else {
      configuredColumns = configuredColumns.filter((column) => !Object.keys(CommonFrameEntityFieldsEnum).includes(column.def));
    }

    if (this.actionsEnabled) {
      const actions = await this.getActions();
      if (actions.value.length) {
        configuredColumns.push(actions);
      }
    }

    if (!this.columnList) {
      this.columnList = configuredColumns;
    }
    this.applyUserPreference();
  }

  async getActions() {
    this.tableActions.subscribe(async (action) => {
      if (action) {
        switch (action.type) {
          case "Modifica":
            this.goToView.emit({entityName: this.entityName, entityId: action.element.id});
            break;
          case "Elimina":
            this.openDeleteConfirmationDialog.emit(action.element);
            break;
          default:
            this.actionEmitter.emit(action);
        }
      }
    });

    const actions: any = {
      def: "actions",
      name: "AZIONI",
      value: [
      ],
    };

    if (await firstValueFrom(this.hasPermissionPipe.transform([`${this.entityName}.${BasePermissions.update}`]))) {
      actions.value.push({
        icon: 'edit',
        type: "Modifica",
      });
    }

    if (await firstValueFrom(this.hasPermissionPipe.transform([`${this.entityName}.${BasePermissions.delete}`]))) {
      actions.value.push({
        icon: 'delete',
        type: "Elimina",
      });
    }

    if (this.actions?.length) {
      actions.value.push(...this.actions);
    }

    return actions;
  }

  applyColumnsConfig(generatedColumns: any[]): ColumnModel[] {
    if (!this.entityName) {
      return generatedColumns;
    }
    const configColumns = this.entityConfig?.columns;
    if (!this.entityConfig || !configColumns?.length) {
      return generatedColumns;
    }

    // using config columns only
    if (this.entityConfig.ignoreGeneratedColumns) {
      return configColumns as ColumnModel[];
    }

    // updating generated columns with config columns
    for (const configColumn of configColumns as any[]) {
      const generatedColumnIndex = generatedColumns?.findIndex((_column) => _column.def === configColumn.def);
      if (generatedColumnIndex !== -1) {
        for (const columnConfigPropName of Object.keys(configColumn)) {
          generatedColumns[generatedColumnIndex][columnConfigPropName] = configColumn[columnConfigPropName];
        }
      } else {
        generatedColumns.push(configColumn as ColumnModel);
      }
    }
    return generatedColumns as ColumnModel[];
  }

  applyUserPreference() {
    if (!this.entityName) {
      return;
    }
    const columnsPreference = this.userPreference?.fullTable?.[this.entityName]?.columns;
    if (!columnsPreference) {
      return;
    }

    this.columnList = this.columnList?.map((column: any) => {
      const columnPreference = columnsPreference[column.def];
      if (!columnPreference) {
        return column;
      }
      Object.keys(columnPreference).forEach((key) => {
        column[key] = columnPreference[key];
      });
      return column;
    });
  }

  getGeneratedColumns(metadata: FrameEntityMetadataModel): ColumnModel[] {
    const columns = [];

    for (const metadataItem of metadata.columns) {
      const columnName = metadataItem.name;
      const columnType = metadataItem.type;
      const translatedColumnName = this.translationService.getTranslatedFieldName(this.entityName as string, columnName);
      const column: ColumnModel = {
        def: columnName,
        name: translatedColumnName,
        value: (element: any) => element[columnName],
        sort: true,
      };

      if (columnName === 'id') {
        column.type = FullTableColumnType.number;
        column.click = (element: any) => this.goToView.emit({entityName: this.entityName, entityId: element.id});
        column.tooltip = 'Visualizza dettaglio';
      } else {
        const mappedColumnType = frameEntityMapping(columnType);

        switch (mappedColumnType) {
          case FrameEntityType.number:
            column.type = FullTableColumnType.number;
            break;
          case FrameEntityType.date:
            column.value = (element: any) => this.datePipe.transform(
              element[columnName],
              this.dateFormat,
              undefined,
              this.locale
            );
            column.type = FullTableColumnType.date;
            break;
          case FrameEntityType.string:
            column.value = (element: any) => element[columnName] || '';
            column.type = FullTableColumnType.string;
            break;
          case FrameEntityType.boolean:
            column.type = FullTableColumnType.boolean;
            break;
          default:
            console.log('unhandled', columnName, columnType);
            break;
        }
      }

      columns.push(column);
    }

    return columns;
  }

  columnWidthChange(event: {def: string, width?: number}) {
    if (!this.entityName || !event.def || typeof event.width !== 'number') {
      return;
    }

    const entityName = this.entityName;
    const preference: UserPreferenceModel = this.getUserPreferenceWithUpdatedColumn(
      entityName,
      event.def,
      'width',
      event.width
    )

    this.store.dispatch(updateUserPreference({ preference }));
  }

  columnsOrderChange(columns: {def: string, order?: number}[]) {
    if (!this.entityName || !columns.length) {
      return;
    }

    const entityName = this.entityName;
    let preference: UserPreferenceModel = {...this.userPreference || {}};;


    for (const column of columns) {
      console.log(entityName, column.def, column.order);
      preference = this.getUserPreferenceWithUpdatedColumn(
        entityName,
        column.def,
        'order',
        column.order,
        preference
      );
    }

    this.store.dispatch(updateUserPreference({ preference }));
  }

  getUserPreferenceWithUpdatedColumn(entityName: string, def: string, key: string, value: unknown, preference?: UserPreferenceModel) {
    if (!preference) {
      preference = {...this.userPreference || {}};
    }
    preference = {
      ...preference,
      fullTable: {
        ...preference?.fullTable || {},
        [entityName]: {
          ...preference?.fullTable?.[entityName] || {},
          columns: {
            ...preference?.fullTable?.[entityName]?.columns || {},
            [def]: {
              ...preference?.fullTable?.[entityName]?.columns?.[def] || {},
              [key]: value
            },
          }
        },
      }
    };
    return preference;
  }
}
