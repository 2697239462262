import { Routes } from "@angular/router";
import { DisabledUserComponent } from "./auth/components/disabled-user/disabled-user.component";
import { LoginComponent } from "./auth/components/login/login.component";
import { PasswordResetComponent } from "./auth/components/password-reset/password-reset.component";

export const FrameExternalRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'disabled-user',
        component: DisabledUserComponent,
    },
    {
        path: 'reset-password',
        component: PasswordResetComponent,
    },
];