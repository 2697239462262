import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BaseForm, DynamicFormComponent } from '@overflo-srl/dynamic-form';
import { FrameEntityViewComponent } from '../../../frame-entity/components/frame-entity-view/frame-entity-view.component';
import { FrameEntityHttpService } from '../../../frame-entity/services/frame-entity-http/frame-entity-http.service';
import { TreeItemModel } from '../../models/tree-item.model';
import { TreeNodeDialogDataModel } from '../../models/tree-node-dialog-data.model';
import { CdkPortal, CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'lib-tree-node-dialog',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    FrameEntityViewComponent,
    CdkPortalOutlet,
    CdkPortal,
    DynamicFormComponent,
    MatProgressSpinner,

  ],
  templateUrl: './tree-node-dialog.component.html',
  styleUrl: './tree-node-dialog.component.scss'
})
export class TreeNodeDialogComponent<T extends TreeItemModel<T>> implements OnInit {

  entityId?: number;
  forms?: BaseForm[];
  loading: boolean = true;
  entity?: any;
  create?: boolean;




  portal?: ComponentPortal<any>;

  constructor(
    public dialogRef: MatDialogRef<TreeNodeDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: TreeNodeDialogDataModel<T>,
    private frameEntityService: FrameEntityHttpService<T>,
  ) {
    this.create = this.data.create;
    const unparsedNodeId = this.data.nodeId;
    if (unparsedNodeId) {
      this.entityId = parseInt(unparsedNodeId);
    }
  }




  async ngOnInit(): Promise<void> {
    if (this.data.component) {
      this.portal = new ComponentPortal(this.data.component);
    }

    await this.populateForms();

    this.loading = false;
  }

  async populateForms() {
    if (!this.data.entityName || !this.data.forms) {
      return;
    }

    let entity: T | undefined = undefined;

    if (!this.data.create && this.entityId) {
      entity = await this.frameEntityService.getOne(this.data.entityName, this.entityId);
    }

    const parsedForms: BaseForm[] = [];
    this.data.forms.forEach((_form) => {
      const form = {..._form};
      form.value = form.value(entity);
      parsedForms.push(form);
    });

    this.forms = parsedForms;
  }

  async save(formResult: Partial<T>) {
    if (this.data.create) {
      const parent = this.entityId ? {
        id: this.entityId,
      } : undefined;

      formResult = {
        ...formResult,
        leaf: formResult.leaf || false,
        parent: parent
      };
    }

    this.dialogRef.close(formResult);
  }
}
