<div class="component-wrapper">
  <div class="middle-title">
    <div>{{role?.id ? 'Modifica ' : 'Crea nuovo '}}ruolo</div>
    <button color="primary" mat-raised-button (click)="confirm()">Salva</button>
  </div>

  <lib-dynamic-form #dynamicForm (send)="save($event)" [forms]="forms" [showButtonAndEmit]="false" [showConfirmationDialog]="false"></lib-dynamic-form>
  <div fxLayout="row">
    @if (selectedPermissions?.length) {
      <div>
        <div class="middle-title">Permessi selezionati</div>
        <div class="larger-form-fields">
          <form>
            <div class="fields-fill">
              @for (permission of selectedPermissions; track permission) {
                <div class="field">
                  {{permission}}
                </div>
              }
            </div>
          </form>
        </div>
      </div>
    }
  </div>
</div>