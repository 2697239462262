import { Component, Inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'lib-pwa-update-dialog',
  templateUrl: './pwa-update-dialog.component.html',
  styleUrls: ['./pwa-update-dialog.component.scss'],
})
export class PwaUpdateDialogComponent implements OnDestroy {
  countdown: number;
  countDownInterval;

  constructor(public dialogRef: MatDialogRef<PwaUpdateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) {
    this.countdown = 60;
    this.countDownInterval = setInterval(() => {
      this.countdown--;
      if (!this.countdown) {
        clearInterval(this.countDownInterval);
        this.dialogRef.close(true);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.countDownInterval);
  }
}
