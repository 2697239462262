import { Pipe, PipeTransform } from '@angular/core';
import {OperatorsEnum} from "../enums/operators.enum";

@Pipe({
  name: 'isChipOperationIsNullOrNotNull',
  standalone: true,
})
export class IsChipOperationIsNullOrNotNullPipe implements PipeTransform {

  transform(op: string): boolean {
    return op === OperatorsEnum.NOT_NULL || op === OperatorsEnum.NULL;
  }

}
