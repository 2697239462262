export enum DynamicFormControlType {
    select = 'select',
    multipleselect = 'multipleselect',
    text = 'text',
    textarea = 'textarea',
    radio = 'radio',
    checkbox = 'checkbox',
    date = 'date',
    datetime = 'datetime',
    photos = 'photos',
    inputchips = 'inputchips',
}