import { Routes } from "@angular/router";
import { AuthRoutingModule } from "./auth/auth-routing.module";
import { CanLoadGuard } from "./auth/guards/auth-guard/can-load.guard";
import { FrameEntityRoutingModule } from "./frame-entity/frame-entity-routing.module";
import { UserModule } from "./user/user.module";

const guards: any[] = [CanLoadGuard];

export const FrameInternalRoutes: Routes = [
    {
        path: 'frame/entity',
        loadChildren: () => FrameEntityRoutingModule,
        canLoad: guards
    },
    {
        path: "auth",
        loadChildren: () => AuthRoutingModule,
        canLoad: guards,
    },
    {
        path: "users",
        loadChildren: () => UserModule,
        canLoad: guards,
    },
];