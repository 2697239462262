import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) { }

  async getBackendVersion(): Promise<{version: string} | undefined> {
    return this.http.get<{version: string}>(`${this.backendBasePath}/version`).toPromise();
  }
}