import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {QueryJoin, QueryJoinArr, QuerySort, RequestQueryBuilder, SCondition} from '@nestjsx/crud-request';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {lastValueFrom, merge, Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {catchError, debounceTime, filter, map, startWith, switchMap} from 'rxjs/operators';
import {ColumnModel} from '../model/column.model';
import {GetManyModel} from '../model/get-many.model';
import {FullTableDialogComponent} from '../full-table-dialog/full-table-dialog.component';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {ChipListItem} from '../model/chip-list-item.model';
import {isNaN} from 'lodash';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {GenericWithId, IdCode} from '../interfaces/generic-with-id.interface';
import {DefaultFilterModel} from '../model/default-filter.model';
import {OperatorsEnum} from '../enums/operators.enum';
import {ExportOptionsModel} from '../model/export-options.model';
import {TypesEnum} from '../enums/types.enum';
import {SpecialColumnsEnum} from '../enums/special-columns.enum';
import {QueryParamsEnum} from '../enums/query-params.enum';
import {EventSourceTypeEnum} from '../enums/event-source-type.enum';
import {getSearchOperation} from '../shared/utils/utils';
import {QueryParamsService} from '../services/query-params.service';
import {FullTableFilterComponent} from '../full-table-filter/full-table-filter.component';
import {createExcelFile} from '../shared/utils/excel.util';
import {MatMenuModule} from '@angular/material/menu';
import {GetFilterOptionDisplayedValuePipe} from '../pipes/get-filter-option-displayed-value.pipe';
import {IsChipOperationIsNullOrNotNullPipe} from '../pipes/is-chip-operation-is-null-or-not-null.pipe';
import {MatIconModule} from '@angular/material/icon';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {GetColumnFilterOptionsPipe} from '../pipes/get-column-filter-options.pipe';
import {IsBooleanTypePipe} from '../pipes/is-boolean-type.pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {ShowIfTruncatedDirective} from '../directives/show-if-truncated.directive';
import {ApplyColumnValueFunctionPipe} from '../pipes/apply-column-value-function.pipe';
import {MatSliderModule} from '@angular/material/slider'; 
import {FullTableSettingsComponent} from '../full-table-settings/full-table-settings.component';
import {AppendPxPipe} from '../pipes/append-px.pipe';
import {ViewableColumnListPipe} from '../pipes/viewable-column-list.pipe';
import { OrderColumnListPipe } from '../pipes/order-column-list.pipe';

@Component({
  standalone: true,
  selector: 'lib-full-table',
  imports: [
    NgIf,
    NgFor,
    NgStyle,
    NgClass,
    MatMenuModule,
    GetFilterOptionDisplayedValuePipe,
    IsChipOperationIsNullOrNotNullPipe,
    IsBooleanTypePipe,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    FullTableFilterComponent,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ShowIfTruncatedDirective,
    ApplyColumnValueFunctionPipe,
    MatSliderModule,
    FullTableSettingsComponent,
    AppendPxPipe,
    ViewableColumnListPipe,
    OrderColumnListPipe,
  ],
  providers: [
    GetFilterOptionDisplayedValuePipe,
    IsChipOperationIsNullOrNotNullPipe,
    GetColumnFilterOptionsPipe,
    IsBooleanTypePipe,
    AppendPxPipe,
    ViewableColumnListPipe,
    OrderColumnListPipe,
  ],
  templateUrl: `./full-table.component.html`,
  styleUrls: ['./full-table.component.scss'],
})
export class FullTableComponent<T extends GenericWithId> implements OnInit, OnChanges, AfterViewInit {

  @Input() actions = new EventEmitter<void | { type: string, element: T }>();
  @Input() BASE_PATH = '';
  @Input() columnList!: ColumnModel[];
  @Input() columnMobile?: any;
  @Input() defaultSort?: QuerySort | QuerySort[];
  @Input() defaultFilter?: DefaultFilterModel[];
  @Input() enableExport?: boolean = false;
  @Input() enableUrlQueryParams = false;
  @Input() isSelectable?: boolean;
  @Input() isOnlyOneSelectable?: boolean;
  @Input() join?: QueryJoin | QueryJoinArr | (QueryJoin | QueryJoinArr)[];
  @Input() path!: string;
  @Input() pageSize?: number;
  @Input() quickSearchColumns!: string[];
  @Input() search: SCondition = {};
  @Input() selectAllLimit: number = 1000;
  @Input() selectedElements: T[] = [];
  @Input() includeDeleted?: boolean;
  @Input() enableSettingsMenu?: boolean = false;

  @Output() data = new EventEmitter<any[]>();
  @Output() selectedChange = new EventEmitter<T[]>();
  @Output() columnWidthChange = new EventEmitter<{def: string, width?: number}>();
  @Output() columnsOrderChange = new EventEmitter<{def: string, order?: number}[]>();
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  @ViewChild(FullTableFilterComponent) filterComponent!: FullTableFilterComponent<T>;
  elementList: MatTableDataSource<T> = new  MatTableDataSource<T>([]);
  displayedColumns!: string[];
  elementLenght = 0;
  loading = true;
  pageCount = 1;
  quickSearchDebounce: Subject<string> = new Subject();
  quickSearchText?: string;
  selectedElementsIds: IdCode[] = [];
  selectControl = new FormControl();
  columnWidthChangeSubject = new Subject<{def: string, width?: number}>();
  columnsOrderChangeSubject = new Subject<{def: string, order?: number}[]>();

  isMobileLayout = window.innerWidth < 600;
  chipList: ChipListItem[] = [];
  protected readonly TypesEnum = TypesEnum;
  protected readonly SpecialColumnsEnum = SpecialColumnsEnum;

  constructor(
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private queryParamsService: QueryParamsService,
    private viewableColumnListPipe: ViewableColumnListPipe,
    private orderColumnListPipe: OrderColumnListPipe,
    @Inject('BASE_PATH') BASE_PATH: string,
  ) {
    this.BASE_PATH = BASE_PATH;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileLayout = event.target.innerWidth < 600;
  }

  ngOnInit(): void {
    if (!this.isSelectable && this.isOnlyOneSelectable !== undefined) {
      this.isSelectable = true;
    }
    for (const el of this.selectedElements) {
      if (el.id) {
        this.selectedElementsIds.push(el.id);
      }
    }
    this.quickSearchDebounce.pipe(debounceTime(300)).subscribe((value: string) => {
      this.updateQuickSearch(value);
    });
    this.columnWidthChangeSubject.pipe(debounceTime(1000)).subscribe((value) => {
      this.columnWidthChange.emit(value);
    });
    this.columnsOrderChangeSubject.pipe(debounceTime(1000)).subscribe((value) => {
      this.columnsOrderChange.emit(value);
    });
    if (!this.actions) {
      this.actions = new EventEmitter<void | { type: string, element: any }>();
    }

    this.initializeColumns();
    this.initializePaginator();
    if (this.defaultFilter?.length) {
      for (const f of this.defaultFilter) {
        this.chipList.push({column: f.name, value: f.value, operation: f.operation || OperatorsEnum.EQUALS});
      }
    }

  }

  // Initialize columns
  initializeColumns(): void {
    if (!this.columnMobile) {
      this.columnMobile = this.columnList[0];
    }

    this.displayedColumns = this.viewableColumnListPipe.transform(this.columnList).map(x => x.def);

    if (this.isSelectable && !this.columnList.find(col => col.def === SpecialColumnsEnum.selection)) {
      this.columnList.unshift({
        def: "ms-ft-selection",
        name: "Selezione",
        value: (element: T) => element,
        sort: false,
        type: TypesEnum.BOOLEAN,
      });
      this.displayedColumns.unshift(SpecialColumnsEnum.selection);
    }
  }

  // Set custom labels for the paginator
  initializePaginator(): void {
    this.paginator._intl.firstPageLabel = 'prima pagina';
    this.paginator._intl.itemsPerPageLabel = 'elementi per pagina';
    this.paginator._intl.lastPageLabel = 'ultima pagina';
    this.paginator._intl.nextPageLabel = 'pagina successiva';
    this.paginator._intl.previousPageLabel = 'pagina precedente';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      `${page * pageSize + 1} - ${page * pageSize + pageSize > length ? length : page * pageSize + pageSize} di ${length}`;
    if (!this.pageSize) {
      this.pageSize = 5;
    }
  }

  async ngAfterViewInit() {
    if (this.enableUrlQueryParams) {
      await this.initUrlQueryParamsSetup();
    }

    this.setupSorting();
  }

  setupSorting() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.actions)
      .pipe(
        filter((a: any) => {
          return a === undefined || !('type' in a);
        }),
        startWith({}),
        switchMap(() => {
          this.loading = true;
          return this.getData(this.sort.active, this.sort.direction.toUpperCase(), this.paginator.pageSize, this.paginator.pageIndex, this.search);
        }),
        map(data => {
          this.loading = false;
          this.pageCount = data.pageCount;
          this.elementLenght = data.total;
          this.data.emit(data.data);
          return data.data;
        }),
        catchError(() => {
          this.loading = false;
          return of([]);
        })
      ).subscribe((data: T[]) => {
      this.elementList.data = data;
      this.enableOrDisableSelectAll();
      this.checkOrUncheckSelectAll();
    });
  }

  async updateQuickSearchWithDebounce(value: string) {
    this.paginator.pageIndex = 0;
    this.quickSearchDebounce.next(value);
    this.enableOrDisableSelectAll();
    await this.checkOrUncheckSelectAll()
  }

  updateQuickSearch(value: string): void {
    if (value) {
      this.quickSearchText = value.toLowerCase();
    } else {
      this.quickSearchText = undefined;
    }
    this.actions.emit();
  }

  getData(sort: string, order: string, limit: number, page: number, search: SCondition): Observable<GetManyModel<T>> {
    let qbSort: QuerySort | QuerySort[] = {field: 'id', order: 'DESC'};
    if (sort) {
      qbSort = {field: sort, order: order.toUpperCase() === 'ASC' ? 'ASC' : 'DESC'};
    } else if (this.defaultSort) {
      qbSort = this.defaultSort;
    }
    const qb = RequestQueryBuilder.create({
      sort: qbSort,
      page: page + 1,
      limit,
      resetCache: true
    });

    if (this.includeDeleted) {
      qb.setIncludeDeleted(1);
    }

    const s: SCondition = {$and: []};

    if (search) {
      s.$and?.push(search);
    }

    this.getQuickSearchOperation(s);
    this.getFiltersOperation(s);
    qb.search(s);
    if (this.join) {
      qb.setJoin(this.join);
    }
    const requestUrl =
      `${this.BASE_PATH}/${this.path}?${qb.query()}`;
    return this.http.get<GetManyModel<T>>(requestUrl);
  }

  getQuickSearchOperation(s: SCondition) {
    if (this.quickSearchText) {
      const orClause: SCondition[] = [];
      for (const column of this.columnList){
        if (this.isColumnSearchable(column)) {
          if (column.type === TypesEnum.STRING){
            orClause.push({[column.def]: {$contL: this.quickSearchText}});
          }else if(column.type === TypesEnum.NUMBER){
            if (!isNaN(+this.quickSearchText)){
              orClause.push({[column.def]: {$eq: this.quickSearchText.trim()}});
            }else if(this.isTheOnlyColumnSearchable()){
              orClause.push({['id']: {$lt: 0}});
            }
          }
        }
      }
      s.$and?.push({$or: orClause});
    }
  }

  getFiltersOperation(s: SCondition) {
    if (this.chipList) {
      for (const chip of this.chipList) {
        const cl = this.columnList.find(c => c.name === chip.column);
        const type = cl?.type as TypesEnum || typeof chip.value as TypesEnum;
        const def = cl ? cl.def : chip.column;
        if (chip.value || chip.value === false) {
          s.$and?.push({[def]: getSearchOperation(chip.operation, chip.value, type)});
        }
        if (chip.start) {
          s.$and?.push({[def]: getSearchOperation(OperatorsEnum.GREATER_THAN, chip.start, type)});
        }
        if (chip.end) {
          s.$and?.push({[def]: getSearchOperation(OperatorsEnum.LOWER_THAN, chip.end, type)});
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.actions.emit();
    this.columnList = this.orderColumnListPipe.transform(this.columnList);
    this.initializeColumns();
  }

  openMobileDialog(element: any): void {
    this.dialog.open(FullTableDialogComponent, {data: {element, columnList: this.columnList, actions: this.actions}});
  }

  clickedAction(element: any): void {
    this.actions.emit({type: 'click', element});
  }

  removeChip(chip: ChipListItem, fieldName: 'value' | 'start' | 'end'): void {
    const index = this.chipList.findIndex(c =>
      c[fieldName] === chip[fieldName] &&
      c.column === chip.column &&
      c.operation === c.operation
    );
    if (index >= 0) {
      this.chipList[index][fieldName] = null;
      if (
        !this.chipList[index].value &&
        !this.chipList[index].start &&
        !this.chipList[index].end
      ) {
        this.chipList.splice(index, 1);
      }
    }
    this.actions.emit();
  }

  getViewableColumnList(): ColumnModel[] {
    return this.columnList.filter(c => c.hidden === null || c.hidden === undefined || !c.hidden);
  }

  async export(options?: ExportOptionsModel) {
    this.loading = true;
    let search: SCondition = {$and: [this.search]};

    if (options?.additionalFilters && options.additionalFilters.length > 0) {
      this.applyAdditionalFilters(search, options.additionalFilters);
    }

    const elements = await this.getAllDataForExcel(search);

    createExcelFile(elements, this.columnList, options);
    this.loading = false;
  }

  applyAdditionalFilters(search: SCondition, additionalFilters: DefaultFilterModel[]) {
    for(let filter of additionalFilters) {
      const col = this.columnList.find(c => c.name === filter.name);
      if (col){
        const type: TypesEnum = col?.type as TypesEnum || typeof filter.value as TypesEnum;
        const def = col ? col.def : filter.name;
        search.$and?.push({[def]: getSearchOperation(filter.operation ?? OperatorsEnum.EQUALS, filter.value, type)});
      }
    }
  }

  onWidthChange(updatedColumn: ColumnModel): void {
    const columnIndex = this.columnList.findIndex(col => col.def === updatedColumn.def);
    if (columnIndex === -1) {
      return;
    }
    this.columnList[columnIndex].width = updatedColumn.width;
    this.columnWidthChangeSubject.next({ 
      def: this.columnList[columnIndex].def, 
      width: this.columnList[columnIndex].width 
    });
  }

  onOrderChange(orderedColumns: ColumnModel[]): void {
    this.columnList = orderedColumns;
    this.initializeColumns();

    this.columnsOrderChangeSubject.next(
      orderedColumns.map((col) => ({
        def: col.def,
        order: col.order
      }))
    );
  }

  async getAllDataForExcel(search: SCondition) {
    let elements : any[] = [];
    const result = await lastValueFrom(this.getData(this.sort.active, this.sort.direction.toUpperCase(), this.paginator.pageSize, 0, search));
    elements = elements.concat(result?.data || []);

    if (result?.pageCount && result.pageCount > 1) {
      for (let page = 1; page < result?.pageCount; page++) {
        const result = await lastValueFrom(this.getData(this.sort.active, this.sort.direction.toUpperCase(), this.paginator.pageSize, page, search));
        elements = elements.concat(result?.data || []);
      }
    }

    return elements;
  }


  selectElement(element: T, checked: boolean) {
    if (element.id){
      const oldElement= this.selectedElements.find((e) => e.id === element.id);
      if (checked && !oldElement) {
        this.selectedElements.push(element);
        this.selectedElementsIds.push(element.id);
      } else if (!checked && oldElement) {
        let index = this.selectedElements.findIndex(x => x.id === element.id)
        this.selectedElements.splice(index, 1);
        index = this.selectedElementsIds.findIndex(x => x === element.id)
        this.selectedElementsIds.splice(index, 1);
      }
    }
  }

  async onSelectAllClick(matCheckboxChange: MatCheckboxChange) {
    if (this.elementLenght > 0){
      const allElements = await this.getAllElements();

      for (let element of allElements){
        this.selectElement(element, matCheckboxChange.checked)
      }
      this.selectedChange.emit(this.selectedElements);
    }
  }

  async selectSingleElement(element: T, checked: boolean): Promise<void>{
    if (this.isOnlyOneSelectable && checked && this.selectedElements.length > 0){
      this.selectElement(this.selectedElements[0], false);
    }
    this.selectElement(element, checked);
    this.selectedChange.emit(this.selectedElements);
    await this.checkOrUncheckSelectAll();
  }

  private enableOrDisableSelectAll(){
    if (this.isSelectable){
      if (this.elementLenght <= this.selectAllLimit && this.elementLenght > 0){
        this.selectControl.enable();
        return;
      }
      this.selectControl.disable();
    }
  }

  private async checkOrUncheckSelectAll(){
    if (this.isSelectable){
      if (this.selectedElements.length >= this.elementLenght && this.elementLenght > 0){
        const allElements = await this.getAllElements();
        const allElementsIds = allElements.map((el) => el.id);

        for (let elementId of allElementsIds){
          if (elementId && !this.selectedElementsIds.includes(elementId)){
            this.selectControl.setValue(false);
            return;
          }
        }

        this.selectControl.setValue(true);
        return;
      }

      this.selectControl.setValue(false);
    }
  }

  private async getAllElements(): Promise<T[]>{
    const result = await lastValueFrom(this.getData(this.sort.active, this.sort.direction.toUpperCase(), this.elementLenght, 0, this.search));
    return result.data;
  }

  private isColumnSearchable(column: ColumnModel){
    return column.def !== SpecialColumnsEnum.actions &&
          (!this.quickSearchColumns?.length || this.quickSearchColumns.includes(column.def));
  }

  private isTheOnlyColumnSearchable(){
    return this.columnList.length === 1 || this.quickSearchColumns?.length === 1;
  }

  // starts the setup of all things related to the url query params and sets up the listeners for th subsequent changes
  private async initUrlQueryParamsSetup() {
    this.chipList = await this.queryParamsService.handleFilters(this.chipList, this.columnList);
    await this.createAndHandleFilterQuery(EventSourceTypeEnum.ONLOAD);
    this.listenToStateChangeEvents();
  }

  private async createAndHandleFilterQuery(origin: string) {
    const queryRes = await this.queryParamsService.createFilterQuery(
      origin, this.chipList, this.columnList, this.sort, this.pageSize, this.quickSearchText);

    if (queryRes[QueryParamsEnum.QUICK_SEARCH] && origin == EventSourceTypeEnum.ONLOAD)
      this.updateQuickSearch(queryRes[QueryParamsEnum.QUICK_SEARCH]);

    this.setPaginationFromQuery(queryRes);
    this.queryParamsService.addQueryParamsToUrl(queryRes, false, origin);
  }

  private setPaginationFromQuery(queryRes: {[index: string]:any}) {
    this.paginator.pageIndex = queryRes[QueryParamsEnum.PAGE_INDEX] - 1;
    this.paginator.pageSize = queryRes[QueryParamsEnum.PAGE_SIZE];
    this.pageSize = queryRes[QueryParamsEnum.PAGE_SIZE];
    this.cd.detectChanges();
  }

  // listens to various events relevant to the directive purposes like sort, page and filter changes.
  private listenToStateChangeEvents(): void {
    this.actions.subscribe( async (value) => {
      // the filter changes exit with undefined as value unlike click or "vizualizza" events, added to not block click events
      if (!value) {
        await this.createAndHandleFilterQuery(EventSourceTypeEnum.EVENT);
      }
    });

    this.sort.sortChange.subscribe((sortChange: MatSort) => {
      this.queryParamsService.applySortChangesToUrlQueryParams(sortChange, this.pageSize);
    });
    this.paginator.page.subscribe((page: PageEvent) => {
      this.queryParamsService.applyPageChangesToUrlQueryParams(page);
    });
  }

}
