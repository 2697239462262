import { FrameEntityMetadataType } from "../enums/frame-entity-metadata-type.enum";
import { FrameEntityType } from "../enums/frame-entity-type.enum";

export const frameEntityMapping = (metadataType: FrameEntityMetadataType): FrameEntityType | undefined => {
    switch (metadataType) {
        case FrameEntityMetadataType.int:
        case FrameEntityMetadataType.int4:
        case FrameEntityMetadataType.decimal:
        case FrameEntityMetadataType.float:
        case FrameEntityMetadataType.doublePrecision:
        case FrameEntityMetadataType.number:
        case FrameEntityMetadataType.integer:
            return FrameEntityType.number;
        case FrameEntityMetadataType.timetz:
        case FrameEntityMetadataType.timestamptz:
        case FrameEntityMetadataType.timestamp:
        case FrameEntityMetadataType.timestampWithoutTimeZone:
        case FrameEntityMetadataType.timestampWithTimeZone:
        case FrameEntityMetadataType.date:
        case FrameEntityMetadataType.time:
        case FrameEntityMetadataType.timeWithoutTimeZone:
        case FrameEntityMetadataType.timeWithTimeZone:
        case FrameEntityMetadataType.fDate:
            return FrameEntityType.date;
        case FrameEntityMetadataType.varchar:
        case FrameEntityMetadataType.char:
        case FrameEntityMetadataType.text:
        case FrameEntityMetadataType.json:
        case FrameEntityMetadataType.jsonb:
        case FrameEntityMetadataType.string:
            return FrameEntityType.string;
        case FrameEntityMetadataType.bool:
        case FrameEntityMetadataType.boolean:
            return FrameEntityType.boolean;
        default:
            console.log('unhandled frameEntityMapping', metadataType);
            return;
    }
}

export const getItemFromLS = (key: string) => {
  if (!key) {
    return;
  }
  const unparsed = localStorage.getItem(key);
  if (!unparsed) {
    return;
  }
  if (unparsed === 'undefined') {
    localStorage.removeItem(key);
    return;
  }
  return JSON.parse(unparsed);
}