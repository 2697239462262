import { LoadingStateEnum } from '../../enums/loading-state.enum';
import { TreeRawDataModel } from '../../models/tree-raw-data.model';

export class TreeTransformedData {
  constructor(public raw: TreeRawDataModel) {}

  areChildrenLoading() {
    return this.raw.childrenLoading === LoadingStateEnum.LOADING;
  }

  isExpandable() {
    return (
      (this.raw.childrenLoading === LoadingStateEnum.INIT || this.raw.childrenLoading === LoadingStateEnum.LOADED) &&
      !!this.raw.childrenIds?.length
    );
  }

  isLeaf() {
    return !this.isExpandable() && !this.areChildrenLoading();
  }
}
