export const RequestTranslations = {
    entityName: 'Richiesta',
    fields: {
        uid: 'UID',
        ipAddress: 'Indirizzo IP',
        createdAt: 'Data richiesta',
    }
    
}

export const RequestFullTableConfig = {
    ignoreGeneratedColumns: false,
    allowedColumns: [
        'uid', 
        'ipAddress', 
        'createdAt', 
    ],
    columns: []
}

export const RequestDynamicFormConfig = {
    ignoreGeneratedForms: false,
    forms: [],
    readonly: true,
}