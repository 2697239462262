import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HasPermissionPipe } from '@overflo1/frame-frontend';
import { AlmaboxPermissions } from 'src/app/almabox.permissions';
import projectVersionJson from '../../../../../project-version.json';
import {MatListModule} from '@angular/material/list'; 
import { AsyncPipe } from '@angular/common';
import { FrameMenuComponent } from '@overflo1/frame-frontend';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    HasPermissionPipe,
    AsyncPipe,
    MatIconModule,
    MatListModule,
    FrameMenuComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [
    HasPermissionPipe,
    AsyncPipe,
  ]
})
export class MenuComponent {

  readonly AlmaboxPermissions = AlmaboxPermissions;
  frontendVersion?: string;

  constructor(
  ) {
    this.frontendVersion = projectVersionJson.version;
  }
}
