export enum ControlTypesEnum {
  select = 'select',
  multipleSelect = 'multipleselect',
  text = 'text',
  password = 'password',
  textArea = 'textarea',
  radio = 'radio',
  checkbox = 'checkbox',
  date = 'date',
  datetime = 'datetime',
  photos = 'photos',
  inputChips = 'inputchips',
}
