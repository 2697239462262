import { Inject, Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RoleFunctionModel} from "../model/role-function.model";
import { HttpCallerService } from '../../shared/services/http-caller/http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class RoleFunctionService extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) {
    super(http, backendBasePath);
  }

  async getOne(roleFunctionId: number, query?: string) {
    return await this.get<RoleFunctionModel>(`/role-function/${roleFunctionId}?${query ?? ''}`);
  }

  async getMany(query?: string) {
    return await this.get<RoleFunctionModel[]>(`/role-function?${query ?? ''}`);
  }

  async createOne(role: RoleFunctionModel) {
    return await this.post(`/role-function`, role);
  }

  async updateOne(role: RoleFunctionModel) {
    return await this.patch(`/role-function/${role.id}`, role);
  }

  async deleteOne(roleFunctionId: number) {
    return await this.delete(`/role-function/${roleFunctionId}`);
  }
}
