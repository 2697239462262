import { Pipe, PipeTransform } from '@angular/core';
import { TreeRawDataModel } from '../../models/tree-raw-data.model';
import { LoadingStateEnum } from '../../enums/loading-state.enum';

@Pipe({
  name: 'areTreeChildrenLoading',
  standalone: true
})
export class AreTreeChildrenLoadingPipe implements PipeTransform {

  transform(node: TreeRawDataModel): unknown {
    return node.childrenLoading === LoadingStateEnum.LOADING;
  }

}
