import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { AppState } from '../../../shared/reducers';
import { canNavigate, getRoles } from '../../selectors/auth.selector';

@Injectable()
export class CanLoadGuard implements CanLoad {
  constructor(private store: Store<AppState>, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.store.select(canNavigate).pipe(
      take(1),
      map(can => {
        if (!can) {
          this.router.navigateByUrl('/login');
          return false;
        }

        // this.router.navigateByUrl('/dashboard');

        return true;
      })
    );
  }
}
