import {AsyncPipe, CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CdkTreeModule} from '@angular/cdk/tree';
import {TreeTransformedData} from '../../services/tree-transformed-data/tree-transformed-data';
import {TreeDataStore} from '../../store/tree-data.store';
import {AreTreeChildrenLoadingPipe} from '../../pipes/are-tree-children-loading/are-tree-children-loading.pipe';
import {TreeService} from '../../services/tree/tree.service';
import {TreeItemModel} from '../../models/tree-item.model';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TreeActionsEnum} from '../../enums/tree-actions.enum';
import {MatDialog} from '@angular/material/dialog';
import {SnackBarService} from '../../../shared/services/snackbar/snackbar.service';
import {
  ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MaterialColorEnum} from '../../../shared/enums/material-color.enum';
import {TreeNodeDialogComponent} from '../tree-node-dialog/tree-node-dialog.component';
import {BaseForm} from '@overflo-srl/dynamic-form';
import {BasePermissions} from '../../../base-permissions';
import {HasPermissionPipe} from '../../../shared/pipes/has-permission/has-permission.pipe';
import {ActionsMergePipe} from "../../../shared/pipes/actions-merge/actions-merge.pipe";
import { ComponentType } from '@angular/cdk/portal';
import {TreeNodeReorderDialogComponent} from "../tree-node-reorder-dialog/tree-node-reorder-dialog.component";

@Component({
  selector: 'lib-tree',
  standalone: true,
  imports: [
    CdkTreeModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    AreTreeChildrenLoadingPipe,
    AsyncPipe,
    HasPermissionPipe,
    ActionsMergePipe
  ],
  providers: [ActionsMergePipe],
  templateUrl: './tree.component.html',
  styleUrl: './tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent<T extends TreeItemModel<T>> implements OnInit {
  @Input() dynamicDialog?: ComponentType<T>;
  @Input() treeService!: TreeService<T>;
  @Input() entityName?: string;
  @Input() forms?: BaseForm[] = [];
  @Input() disableAddRootBtn?: boolean = false;
  readonly BasePermissions = BasePermissions;
  private _dataStore!: TreeDataStore<T>;
  areRootsLoading!: Observable<boolean>;
  roots!: Observable<TreeTransformedData[]>;
  @ContentChild(TemplateRef) itemTemplate!: TemplateRef<any>;
  @Input() actions: any[] = [];
  defaultActions = [
    {icon: 'add_circle', type: TreeActionsEnum.add, permission: BasePermissions.create},
    {icon: 'edit', type: TreeActionsEnum.edit, permission: BasePermissions.update},
    {icon: 'delete', type: TreeActionsEnum.delete, permission: BasePermissions.delete},
    {icon: 'unfold_more', type: TreeActionsEnum.reorder, permission: BasePermissions.update}

  ]
  @Output() actionSelected: EventEmitter<any> = new EventEmitter(); // Emesso quando un'azione viene selezionata

  emitAction(action: any, node: any): void {
    if (this.defaultActions.find((_action) => _action.type === action.type)) {
      this.executeAction(action, node); // Gestione interna Frame
    } else {
      this.actionSelected.emit({action, node}); // Emissione al componente padre
    }
  }

  getChildren = (node: TreeTransformedData) => this._dataStore.getChildren(node.raw.id);
  trackBy = (index: number, node: TreeTransformedData) => this.expansionKey(node);
  expansionKey = (node: TreeTransformedData) => node.raw.id;


  constructor(
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {
  }

  ngOnInit() {
    this._dataStore = new TreeDataStore(this.treeService);
    this.areRootsLoading = this._dataStore.areRootsLoading;
    this.roots = this._dataStore.roots;

    this._dataStore.loadRoots();
  }

  onExpand(node: TreeTransformedData, expanded: boolean) {
    if (expanded) {
      // Only perform a load on expansion.
      this._dataStore.loadChildren(node.raw.id);
    }
  }

  executeAction(
    action: { icon: string, type: string, permission: string },
    node: TreeTransformedData
  ) {
    const nodeId = node.raw.id;
    switch (action.type) {
      case TreeActionsEnum.add:
        this.openNodeCreateOrUpdateDialog(nodeId, true);
        break;
      case TreeActionsEnum.edit:
        this.openNodeCreateOrUpdateDialog(nodeId, false);
        break;
      case TreeActionsEnum.delete:
        this.openDeleteConfirmationDialog(nodeId);
        break;
      case TreeActionsEnum.reorder:
        this.reorderDialog(nodeId);
        break;
      default:
        console.warn('Errore in executeAction', action);
        break;
    }
  }

  openNodeCreateOrUpdateDialog(id?: string, createCheck?: boolean) {
    const dialogRef = this.dialog.open(TreeNodeDialogComponent, {
      data: {
        nodeId: id,
        entityName: this.entityName,
        forms: this.forms,
        create: createCheck,
        component: this.dynamicDialog
      }
    });
    dialogRef.afterClosed().subscribe(
      async (formResult) => {
        if (!formResult) {
          return;
        }
        if (createCheck) {
          await this._dataStore.create(formResult);
        } else if (id) {
          await this._dataStore.update(id, formResult);
        }
        this.snackBarService.openInfoSnackBar(`Nodo ${createCheck ? 'creato' : 'aggiornato'} con successo`);
      }
    )
  }

  openDeleteConfirmationDialog(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Eliminazione nodo`,
        description: `Sei sicuro di voler eliminare il nodo selezionato?`,
        color: MaterialColorEnum.warn
      }
    });
    dialogRef.afterClosed().subscribe(
      async (result) => {
        if (result) {
          await this._dataStore.delete(id);
          this.snackBarService.openInfoSnackBar(`Nodo eliminato con successo`);
        }
      }
    )
  }

  async reorderDialog(id: string) {
    const parentId = this._dataStore['_state'].value.allData.get(id)?.parentId
    if (!parentId) {
      return this.snackBarService.openErrorSnackBar('Le radici non sono riordinabili')
    }
    const siblings = await firstValueFrom(this._dataStore.getSiblings(id))

    const dialogRef = this.dialog.open(TreeNodeReorderDialogComponent, {
      data: {nodes: siblings.map(sibling => sibling.raw)},
    });
    dialogRef.afterClosed().subscribe(
      async (newOrder) => {
        if (newOrder) {
          await this._dataStore.update(parentId, {childrenOrder: newOrder} as Partial<T>, true);
          this.snackBarService.openInfoSnackBar(`Nodi riordinati con successo con successo`);
        }
      }
    );
  }
}
