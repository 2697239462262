import {Component, input, Input, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { FrameEntityHttpService } from '../../services/frame-entity-http/frame-entity-http.service';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { FrameEntityDetailComponent } from '../frame-entity-detail/frame-entity-detail.component';
import { MatButtonModule } from '@angular/material/button';
import { FrameEntityModel } from '../../models/frame-entity.model';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import {concatAll} from "rxjs";


@Component({
  selector: 'lib-frame-entity-view',
  standalone: true,
  imports: [
    FrameEntityDetailComponent,
    MatButtonModule,
    RouterModule,
    RouterLink,
  ],
  templateUrl: './frame-entity-view.component.html',
  styleUrl: './frame-entity-view.component.scss'
})
export class FrameEntityViewComponent implements OnInit {

  @ViewChild(FrameEntityDetailComponent) frameEntityDetailComponent?: FrameEntityDetailComponent;
  @Input() entityName?: string;
  @Input() entityId?: number;
  @Input() entity?: FrameEntityModel;
  translatedEntityName?: string;

  constructor(
    private frameEntityService: FrameEntityHttpService<any>,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params) => {
      const entityId = params?.['entityId'];
      const entityName = params?.['entityName'];
      if (!this.entityId && entityId) {
        this.entityId = entityId;
      }
      if (!this.entityName && entityName) {
        this.entityName = entityName;
      }
      if (this.entityName) {
        this.translatedEntityName = this.translationService.getTranslatedEntityName(this.entityName as string);
      }
    })
  }

  confirm() {
    if (!this.frameEntityDetailComponent) {
      return;
    }
    this.frameEntityDetailComponent.confirm();
  }

  async save(data: any) {
    if (!this.entityName) {
      return;
    }

    console.log('save', data);

    if (this.entityId && !isNaN(this.entityId)) {
      await this.frameEntityService.updateOne(this.entityName, data);
      this.snackBarService.openInfoSnackBar(`Entità ${this.translatedEntityName} aggiornata con successo`);
      this.frameEntityDetailComponent?.prepareDynamicForm();
      await this.router.navigate([`/frame/entity/${this.entityName}/`]);
    } else {
      this.entity = await this.frameEntityService.createOne(this.entityName, data);
      this.snackBarService.openInfoSnackBar(`Entità ${this.translatedEntityName} creata con successo`);
      await this.router.navigate([`/frame/entity/${this.entityName}/`]);
      this.entityId = this.entity?.id;
    }
  }
}
