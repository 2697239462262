import {DatePipe} from "@angular/common"
import {environment} from "src/environments/environment"
import {MachineModel} from "../machine/models/machine.model";

export const MachineTranslations = {
    entityName: 'Macchina',
    fields: {
      uid: 'UID',
      group: 'Gruppo',
      description: 'Descrizione',
      family: 'Famiglia',
      enabled: 'Attiva',
      removed: 'Rimosso',
      lampHours: 'Ore Lampada',
      periodFrom: 'Periodo inizio',
      periodTo: 'Periodo fine',
      params: 'Parametri',
      projector: 'Proiettore'
    }
}

export const MachineFullTableConfig = {
    ignoreGeneratedColumns: false,
    allowedColumns: [
      'uid',
      'group',
      'description',
      'family',
      'projector',
      'lampHours',
      'enabled',
      'lastRequest',
    ],
    columns: [
      {
        def: "enabled",
        maxWidth: '60px',
      },
      {
        def: "lampHours",
        maxWidth: '130px',
      },
      {
        def: "uid",
        minWidth: '380px',
      },
      {
        def: "uid",
        name: "UID",
        value: (element: MachineModel) => element.uid,
        sort: true,
        type: 'string',
      },
      {
        def: "lastRequest",
        name: "Ultima richiesta",
        value: (element: MachineModel) => new  DatePipe(environment.DATE_LOCALE).transform(
            element.lastRequest.createdAt,
            environment.DATE_TIME_FORMAT
        ),
        sort: false,
      },
    ],
    join: [{ field: 'requests' }]
}

export const MachineDynamicFormConfig = {
    ignoreGeneratedForms: false,
    forms: [],
    readonly: false,
}
