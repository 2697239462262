import { Inject, Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RoleModel} from "../model/role.model";
import { HttpCallerService } from '../../shared/services/http-caller/http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) {
    super(http, backendBasePath);
  }

  async getOne(roleId: number, query?: string) {
    return await this.get<RoleModel>(`/role/${roleId}?${query ?? ''}`);
  }

  async getMany(query?: string) {
    return await this.get<RoleModel[]>(`/role?${query ?? ''}`);
  }

  async createOne(role: RoleModel) {
    return await this.post(`/role`, role);
  }

  async updateOne(role: RoleModel) {
    return await this.patch(`/role/${role.id}`, role);
  }

  async deleteOne(roleId: number) {
    return await this.delete(`/role/${roleId}`);
  }
}
