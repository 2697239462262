<div class="background-overlay"></div>

<div class="header">
    <img src="/img/logo.png" />
</div>

<div class="component-wrapper">
    <div class="middle-title">Utente non abilitato</div>
    <div>Per utilizzare l'applicazione l'utente deve essere abilitato.</div>
    <div class="logout-container">
        <button class="login-button mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base" (click)="logout()">Logout</button>
    </div>
</div>
