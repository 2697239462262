import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {RoleFunctionModel} from "../../model/role-function.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RolePermissionModel} from "../../model/role-permission.model";
import {RolePermissionService} from "../../service/role-permission.service";
import {RoleFunctionService} from "../../service/role-function.service";
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import { BaseForm } from '@overflo-srl/dynamic-form';
import { DynamicFormComponent } from '@overflo-srl/dynamic-form';
import { FunctionFormResultModel } from '../../model/function-form-result.model';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { MatButtonModule } from '@angular/material/button';

export class SelectablePermission extends RolePermissionModel {
  selected: boolean;
  constructor(permission: RolePermissionModel, selected = false) {
    super(permission.id, permission.name);
    this.selected = selected;
  }
}

@Component({
  standalone: true,
  imports: [
    DynamicFormComponent,
    MatButtonModule,
  ],
  selector: 'app-function-edit-dialog',
  templateUrl: './function-edit-dialog.component.html',
  styleUrls: ['./function-edit-dialog.component.scss']
})
export class FunctionEditDialogComponent implements OnInit {

  @ViewChild(DynamicFormComponent) dynamicFormComponent?: DynamicFormComponent;
  roleFunction?: RoleFunctionModel;
  selectablePermissions: SelectablePermission [] = [];
  forms: BaseForm[] = [];
  submitting: boolean = false;
  selectedPermissions: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {roleFunction: RoleFunctionModel},
    private dialogRef: MatDialogRef<FunctionEditDialogComponent>,
    private rolePermissionService: RolePermissionService,
    private roleFunctionService: RoleFunctionService,
    private snackBarService: SnackBarService,
  ) {
    this.roleFunction = data.roleFunction;
  }

  async ngOnInit(): Promise<void> {
    await this.loadPermissions();

    const forms = [
      new BaseForm({
        key: 'name',
        value: this.roleFunction?.name,
        label: 'Nome',
        controlType: 'text',
        required: true,
        readonly: false,
      }),
      new BaseForm({
        key: 'description',
        value: this.roleFunction?.description,
        label: 'Descrizione',
        controlType: 'text',
        required: false,
        readonly: false,
      }),
      new BaseForm({
        key: 'group',
        value: this.roleFunction?.group,
        label: 'Gruppo',
        controlType: 'text',
        required: true,
        readonly: false,
      }),
      new BaseForm({
        key: 'permissions',
        value: this.selectablePermissions.filter((permission) => permission.selected),
        options: this.selectablePermissions.map((permission) => {
          return {
            key: permission.name,
            mnemonicId: permission.name,
            description: '',
            value: permission
          };
        }),
        label: 'Permessi',
        controlType: 'multipleselect',
        required: true,
        readonly: false,
        fullWidth: false
      }),
    ];

    this.forms = this.forms.concat(forms);
  }

  async loadPermissions() {
    const allPermissions = await this.rolePermissionService.getMany();
    let functionPermissions: RolePermissionModel[] = [];
    if(this.roleFunction) {
      functionPermissions = await this.getFunctionPermissions();
    }

    const selectablePermissions = [];
    for(const permission of allPermissions) {
      const match = functionPermissions?.find(el => el.id == permission.id);
      selectablePermissions.push(new SelectablePermission(permission, !!match));
    }
    this.selectablePermissions = selectablePermissions;
  }

  async getFunctionPermissions() : Promise<RolePermissionModel[]> {
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: "permissions"}]);
    const roleFunction = await this.roleFunctionService.getOne(this.roleFunction?.id as number, qb.query());
    return roleFunction?.permissions || [];
  }

  confirm() {
    if (!this.dynamicFormComponent) {
      return;
    }
    this.submitting = true;
    this.dynamicFormComponent.onSubmit();
    this.submitting = false;
  }

  async save(formResult: FunctionFormResultModel) {
    const selectedPermissions: string[] = [];

    formResult.permissions.forEach((permission) => {
      if (!selectedPermissions.includes(permission.name)) {
        selectedPermissions.push(permission.name);
      }
    });
    this.selectedPermissions = selectedPermissions;

    if (!this.submitting) {
      return;
    }

    let result;
    if(this.roleFunction?.id) {
      result = await this.roleFunctionService.updateOne({
        id: this.roleFunction.id,
        version: this.roleFunction.version,
        ...formResult
      });
      this.snackBarService.openInfoSnackBar(`Funzione aggiornata con successo`);
    } else {
      result = await this.roleFunctionService.createOne(formResult as RoleFunctionModel);
      this.snackBarService.openInfoSnackBar(`Funzione creata con successo`);
    }
    this.dialogRef.close(result === null ? true : result);
  }
}
