<div class="p-10">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <mat-dialog-content class="mat-body" data-cy="confirmationDialog">
    {{ data.description }}
  </mat-dialog-content>

  <mat-dialog-actions class="flex-end">
    <button mat-flat-button mat-dialog-close>No</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">Si</button>
  </mat-dialog-actions>
</div>

